import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CheckinEntry} from "../../models/checkin-entry.model";
import { Worker } from '../../models/worker.model';
import {TelaxsCallsService} from "../../services/telaxs-calls.service";
import { PhoneDisplayPipe } from 'src/app/pipes/phone-number.pipe'; //used in html to convert to xxx-xxx-xxxx format

@Component({
  selector: 'app-worker-details',
  templateUrl: './worker-details.component.html',
  styleUrls: ['./worker-details.component.scss']
})

export class WorkerDetailsComponent implements OnInit {

  // init loading overlay
  loadingOverlayDisplay: string = 'none';
  
  // init object for worker data to display
  workerData: Worker;

  constructor( @Inject(MAT_DIALOG_DATA) public data: {entry: CheckinEntry, parent: string},
               private dialogRef: MatDialogRef<WorkerDetailsComponent>,
               private service: TelaxsCallsService) { }

  ngOnInit(): void {
    // get worker data by EmpID listed in selected CheckinEntry
    this.getWorkerData(this.data.entry.EmpID);
  }

  getWorkerData(id: string){
    // IMPORTANT //
    // Is there a get site by ID/Name lambda to use here? //
    //  -- If so, update to call said lambda to set phone and notes //

    this.workerData = {
      id: id,
      firstName: this.data.entry.FirstName,
      lastName: this.data.entry.LastName,
      lastCheckin: this.data.entry.CheckInTime,
      defaultPhoneNum: null,
      organization: this.data.entry.Organization,
      notes: "N/A"
    };
  }

  close() {
    this.dialogRef.close('CANCEL');
  }

}
