import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phoneDisplay'})
    export class PhoneDisplayPipe implements PipeTransform {
        transform(input:String): String {
          var areaCode = input.slice(0, 3);
          var exchangeCode = input.slice(3,6)
          var lineNum = input.slice(6);
          
          return areaCode + "-" + exchangeCode + "-" + lineNum;
        };
    };
