
<div class="main-container" id="seting-modal">
  <!-- loading overlay for tables -->
<div *ngIf="showLoader" class="processing-overlay loading-overlay loader-large bg-white" [style.display]="loadingOverlayDisplay">
  <de-loader></de-loader>
</div>
<div *ngIf="!showLoader">
  <div class="processing-overlay" [style.display]="successOverlayDisplay">
    <div class="overlay-content">
      <div class="checkmark"><img src= "assets/icons/checkmark.png" width="144" height="93" alt="success"></div>
      <h2 class="overlay-info">Success!</h2>
    </div>
  </div>
  <div class="close-button h-24 custom-mtl-neg-5" (click)="close()">
    <div class="mat-icon-button">
      <mat-icon><img src="assets/icons/x.png" width="20" height="20" alt="close"></mat-icon>
    </div>
  </div>
  <h2 [ngClass]="isNOC ? 'text-center font-bold text-teal-darker mx-32 mt-16' : 'text-center font-bold text-teal-darker'">
    <span *ngIf="isNOC">{{data.entry.LastName}}, {{data.entry.FirstName}} @</span>
    {{siteData.siteName}}
  </h2>
  <h3 *ngIf="!locationServiceUnavailable" class="text-center">{{siteData.streetAddress}}, {{siteData.city}}, {{siteData.state}}</h3>
  <h2 *ngIf="locationServiceUnavailable" class="text-center">{{siteData.siteAbbreviation}}</h2>
  <h3 class="text-center font-bold mt-16 mb-16 text-red" *ngIf="data.entry.CheckOutTime != null">Checked Out</h3>
  <h3 class="text-center font-bold mt-16 mb-16 text-green-dark" *ngIf="data.entry.CheckOutTime == null">Checked In</h3>

  <!-- if from user, show before check in details without link to open -->
  <div *ngIf="!isNOC" class="request-container flex-box de-component de-component--gray">
    <h3 class="text-2xl text-teal-darker mb-32">Site Details</h3>
    <div *ngIf="!locationServiceUnavailable" class="content text-xl">
      <label for="site-id" class="font-bold" >Abbreviation:</label>
        <p id="site-id" class="custom-mb-5 custom-mt-5">{{siteData.siteAbbreviation}}</p>
      <hr class="text-gray mt-24 mb-16">
      <label for="name" class="font-bold">County:</label>
        <p id="name" class="custom-mb-5 custom-mt-5">{{siteData.county}}</p>
    </div>
    <div *ngIf="locationServiceUnavailable" class="content text-xl">
      <h3  class="font-color-red">Location service is currently unavailable.</h3>
      <h3  class="font-color-red">You will still be able to checkout.</h3>
    </div>
    
  </div>
  <br *ngIf="!isNOC">
  <!-- check in details -- same regardless of from user or noc -->
  <div class="request-container flex-box de-component de-component--gray">
    <h3 class="text-2xl text-teal-darker mb-32">Check-In Details</h3>
    <div class="content text-xl">
      <label for="reason" class="font-bold">Reason:</label>
        <p id="reason" class="custom-mb-5 custom-mt-5">{{data.entry.VisitReason}}</p>
      <hr class="text-gray mt-16 mb-16">
      <label for="checkin" class="font-bold">Check In Time:</label>
        <p id="checkin" class="custom-mb-5 custom-mt-5">{{data.entry.CheckInTime | date: 'MMM dd, yyyy @ hh:mm a (z)'}}</p>  
      <div *ngIf="data.entry.CheckOutTime != null">
        <hr class="text-gray mt-24 mb-16">
        <label for="checkout" class="font-bold">Check Out Time:</label>
          <p id="checkout" class="custom-mb-5 custom-mt-5">{{data.entry.CheckOutTime | date: 'MMM dd, yyyy @ hh:mm a (z)'}}</p>
      </div>
    </div>
  </div>
  <br>
  <!-- if from noc, show after check in details with link to open -->
  <div *ngIf="isNOC" class="request-container flex-box de-component de-component--gray">
    <div class="float-right w-1/5 pr-16 pt-8">
      <img class="float-right open-details" (click)="openSite(siteData.siteAbbreviation)" src= "assets/icons/popout.png" width="20" height="20" alt="open">
    </div>
    <h3 class="text-2xl text-teal-darker mb-32">Site Details</h3>
    <div class="content text-xl">
      <label for="site-id" class="font-bold" >Abbreviation:</label>
        <p id="site-id" class="custom-mb-5 custom-mt-5">{{siteData.siteAbbreviation}}</p>
    </div>
  </div>
  <br *ngIf="isNOC">
  <!-- if from noc, give link to open worker details  -->
  <div *ngIf="isNOC" class="request-container flex-box de-component de-component--gray">
    <div class="float-right w-1/5 pr-16 pt-8">
      <img class="float-right open-details" (click)="openWorker(data.entry)" src= "assets/icons/popout.png" width="20" height="20" alt="open">
    </div>
    <h3 class="text-2xl text-teal-darker">Open Contractor Details</h3>
  </div>
  <div class="message-btns mx-auto match-btn-width-180" *ngIf="data.entry.CheckOutTime == null && enableCheckout">
    <button class="danger-button content-center" (click) = "confirmCheckOut(data.entry)">Check Out</button>
  </div>
</div>
</div>
