<div class="overlap">
  <div class="under-container">
    <img src="/../assets/icons/404-error-desktop.jpg">
  </div>
  <div class="over-container">
    <h1>Sorry - we're unable to locate your page.</h1>
    <h3>Your link may be incorrect or you may not have access.</h3>
    <h4>Please ensure you are using the most recent link provided
      or try clearing your browser history to refresh access tokens. </h4>
  </div>
</div>