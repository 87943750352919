export class CheckinSite {
  score: number;
  state: String;
  city: String;
  siteAbbreviation: String;
  siteName: String;
  regionName: String;
  county: String;
  streetAddress: String;

  constructor(
    score: number = 0,
    state: String = '',
    city: String = '',
    siteAbbreviation: String ='',
    siteName: String ='',
    regionName: String = '',
    county: String= '',
    streetAddress: String=''
  ) {
    this.score = score;
    this.state = state;
    this.city = city;
    this.siteAbbreviation = siteAbbreviation;
    this.siteName = siteName;
    this.regionName = regionName;
    this.county = county;
    this.streetAddress = streetAddress;
  }
}
