import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import { Router } from '@angular/router';
import {CheckinEntry} from "../../models/checkin-entry.model";
import {TelaxsCallsService} from "../../services/telaxs-calls.service";

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})

export class ConfirmActionComponent implements OnInit {

  // standard loading overlay
  loadingOverlayDisplay: string = 'none';

  // init confirmation pop up text
  confirmHeader: string = '';
  confirmSubheader: string = '';
  confirmButton: string = '';


  constructor( @Inject(MAT_DIALOG_DATA) public data: {entry: CheckinEntry, confirmationType: string},
               private dialogRef: MatDialogRef<ConfirmActionComponent>,
               public dialog: MatDialog) { }

  ngOnInit(): void {

    // switch on various different confirmation types -- but right now only checkout
    if(this.data.confirmationType == "checkout") {
      this.confirmHeader = 'Check out of ' + this.data.entry.SiteID;
      this.confirmSubheader = 'Are you sure you want to check out?';
      this.confirmButton = 'CHECK OUT';
    }
    
  }
  
  close() {
    this.dialogRef.close('CANCEL');
  }

  // send confirm back for source to handle next action
  confirm() {
    this.dialogRef.close('CONFIRM');
  }
}
