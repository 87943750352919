import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/app/config/app.config';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentObserver } from '@angular/cdk/observers';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
  memberOfNOC: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  baseURL: string;
  adminGroupName_Developers: string;
  adminGroupID_Developers: string;
  adminGroupName_NOC: string;
  adminGroupID_NOC: string;

  telaxsScope_AccessAsUser: string;
  insiteScope_AssestsRead: string;

  constructor(
    private config: AppConfig,
    private http: HttpClient,
    private appConfig: AppConfig
  ) {
    console.log(`Your environment is set to ${this.config.getConfig('envName')}`);
    // get all config vars
    this.adminGroupName_Developers = this.config.getConfig('adminGroupName_Developers');
    this.adminGroupID_Developers = this.config.getConfig('adminGroupID_Developers');
    this.adminGroupName_NOC = this.config.getConfig('adminGroupName_NOC');
    this.adminGroupID_NOC = this.config.getConfig('adminGroupID_NOC');
    this.baseURL = this.appConfig.getConfig('telaxsBaseURL'); 
   
  }

  confirmNOC() : Observable<any> {
    let url  = `${this.baseURL}`+'/worker/validate/user';

    return this.http.get(url);
    // rather than filtering on ID and having a 403 logged when not found, search by name and if length > 0, confirm IDs returned match
    // return this.http.get(this.GRAPH_ENDPOINT + '/memberOf/microsoft.graph.group?$count=true'
    //   + '&$search="displayName:'+ this.adminGroupName_Developers +'" OR "displayName:'+this.adminGroupName_NOC +'"',
    //   {headers:{'ConsistencyLevel':'eventual'}})
    //   .pipe(
    //     map((response: any) => {
    //       this.memberOfNOC.next(null);
    //       var overrideResponse = null;
    //       if(response.value.length > 0 && response.value != null){
    //         response.value.forEach(group => {
    //           if(group.id == this.adminGroupID_Developers || group.id == this.adminGroupID_NOC){
    //             this.memberOfNOC.next(response);
    //             overrideResponse = response;              } 
    //         });
    //         return overrideResponse;
    //       } else {
    //         return overrideResponse;
    //       }
    //     })
    //   ); 

    //return of(true)
  }


}
