import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class NOCGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService, 
    private router: Router) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        // return this.authService.confirmNOC().pipe(
        //     map(noc => {
        //         if(!noc) {
        //             this.router.navigateByUrl('/notFound');
        //             return false;
        //         }
        //         return true;
        //     })
        // );
        return true;
    }
}