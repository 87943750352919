
import { Injectable, Injector } from '@angular/core';

// @ts-ignore
import {environment} from 'src/environments/environment';
import {environments} from '../../config/env.config';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()

export class AppConfig {
  private config: any;
  currentEnvironment = environment.env;
  environmentConfigs = environments;
  constructor(private injector: Injector) {
    this.config = this.environmentConfigs[this.currentEnvironment];
  }
  /*
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    //this.config = this.environmentConfigs[this.currentEnvironment];
    return this.config[key];
  }

  public load() {
    this.config = this.environmentConfigs[this.currentEnvironment];
  }

  public loadNOC() : Promise<any> {
    return new Promise(((resolve, reject) => {
      this.injector.get(AuthenticationService).confirmNOC()
        .toPromise()
        .then (res => {
          resolve(res);
        })
    }))
    
  }
}
