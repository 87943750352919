import { LogLevel, BrowserCacheLocation, Configuration } from '@azure/msal-browser'
import { env } from 'src/config/env';
import { environment } from 'src/environments/environment';

export type EnvType = "local" | "hosted";
export const currentEnv: EnvType = env.env !== 'local' ? 'hosted' : 'local';

const logLevel: LogLevel = currentEnv === 'local' ? LogLevel.Error : LogLevel.Verbose;
const isIE: boolean = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export const environments = {
  local: {
    production: false,
    envName: 'local',
    clientKey: '---',
    clientSecret: '---',
    clientID: 'b0b1856c-006a-4ad4-87a1-6e39e42a9ec8',
    authority: 'https://Dukeenergytestb2c.b2clogin.com/Dukeenergytestb2c.onmicrosoft.com/',
    telaxsBaseURL: 'https://telaxs-api-sbx.duke-energy.app',
    b2cScope: 'https://DukeEnergyTestB2C.onmicrosoft.com/b0b1856c-006a-4ad4-87a1-6e39e42a9ec8/custom_b2c_scope',
    authoritySignUpSignIn: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_SignUp_SignIn',
    authorityEditProfile: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_Profile_Edit',
    telaxsBaseURLdev: 'https://telaxs-api-dev.duke-energy.app'
  },
  sbx: {
    production: false,
    envName: 'sbx',
    clientKey: '---',
    clientSecret: '---',
    clientID: 'b0b1856c-006a-4ad4-87a1-6e39e42a9ec8',
    authority: 'https://dukeenergytestb2c.b2clogin.com/dukeenergytestb2c.onmicrosoft.com/',
    telaxsBaseURL: 'https://telaxs-api-sbx.duke-energy.app',
    b2cScope: 'https://DukeEnergyTestB2C.onmicrosoft.com/b0b1856c-006a-4ad4-87a1-6e39e42a9ec8/custom_b2c_scope',
    authoritySignUpSignIn: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_SignUp_SignIn',
    authorityEditProfile: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_Profile_Edit',
    telaxsBaseURLdev: 'https://telaxs-api-dev.duke-energy.app'
  },
  dev: {
    production: false,
    envName: 'dev',
    clientKey: '---',
    clientSecret: '---',
    clientID: 'b0b1856c-006a-4ad4-87a1-6e39e42a9ec8',
    authority: `https://Dukeenergytestb2c.b2clogin.com/Dukeenergytestb2c.onmicrosoft.com/`,
    telaxsBaseURL: 'https://telaxs-api-dev.duke-energy.app',
    b2cScope: 'https://DukeEnergyTestB2C.onmicrosoft.com/b0b1856c-006a-4ad4-87a1-6e39e42a9ec8/custom_b2c_scope',
    authoritySignUpSignIn: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_SignUp_SignIn',
    authorityEditProfile: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_Profile_Edit'
  },
  qa: {
    production: false,
    envName: 'qa',
    clientKey: '---',
    clientSecret: '---',
    clientID: 'b0b1856c-006a-4ad4-87a1-6e39e42a9ec8',
    authority: `https://Dukeenergytestb2c.b2clogin.com/Dukeenergytestb2c.onmicrosoft.com/`,
    telaxsBaseURL: 'https://telaxs-api-qa.duke-energy.app',
    b2cScope: 'https://DukeEnergyTestB2C.onmicrosoft.com/b0b1856c-006a-4ad4-87a1-6e39e42a9ec8/custom_b2c_scope',
    authoritySignUpSignIn: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_SignUp_SignIn',
    authorityEditProfile: 'https://DukeEnergyTestB2C.b2clogin.com/DukeEnergyTestB2C.onmicrosoft.com/B2C_1_DukeId_Profile_Edit'
  },

  prod: {
    production: true,
    envName: 'prod',
    clientKey: '---',
    clientSecret: '---',
    clientID: '2985e437-0ad1-4e02-beb2-788bbcc729ce',
    telaxsBaseURL: 'https://telaxs-api.duke-energy.app',
    b2cScope: 'https://DukeEnergyB2C.onmicrosoft.com/telaxs-api/telaxs.read',
    authoritySignUpSignIn: 'https://DukeEnergyB2C.b2clogin.com/DukeEnergyB2C.onmicrosoft.com/B2C_1_DukeId_SignUp_SignIn',
    authorityEditProfile: 'https://DukeEnergyB2C.b2clogin.com/DukeEnergyB2C.onmicrosoft.com/B2C_1_DukeId_Profile_Edit'
  }
};

export const msalInitialConfig: Configuration = {
  auth: {
    clientId: environments[environment.env].clientID,
    authority: environments[environment.env].authoritySignUpSignIn,
    knownAuthorities: [environments[environment.env].authoritySignUpSignIn,
                        environments[environment.env].authorityEditProfile,],
    redirectUri: '/checkin',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: logLevel,
      piiLoggingEnabled: false
    }
  }
}

export const protectedResourcesConfig: Map<string, Array<string>> = new Map([
  [environments[environment.env].telaxsBaseURL, [environments[environment.env].b2cScope]],
])

export const protectedResourcesConfigSbxAndLocal: Map<string, Array<string>> = new Map([
  [environments[environment.env].telaxsBaseURL, [environments[environment.env].b2cScope]],
  [environments[environment.env].telaxsBaseURLdev, [environments[environment.env].b2cScope]],
])

export const apiConfig: { scopes: string[]} = {
  scopes: [`offline_access `+environments[environment.env].clientID]
};
