<section class="px-16 md:px-24 py-48 md:py-64 bg-gray-lighter">
  <!-- exit site link -->
  <div class="text-teal-darker pl-16 pb-32 back-text" (click)="exit()"> 
    <span *ngIf="siteDetails">&#10094; Exit Site</span>
    <span *ngIf="!siteDetails">&#10094; Back to NOC</span>
  </div>

  <!-- main container -->
  <div class="custom-container-90">

    <!-- site header space w/ main site details and find site search bar -->
    <div [ngClass]="siteDetails ? 'site-header' : 'site-header-empty'">

      <!-- main site details -->
      <div class="float-left">
        <h2 class="font-bold" *ngIf="siteDetails">{{siteDetails.siteName}}</h2>
        <h2 class="font-bold" *ngIf="!siteDetails">Telecom Site Search</h2>
        <h3 class="py-16" *ngIf="siteDetails">{{siteDetails.streetAddress}}, {{siteDetails.city}}, {{siteDetails.state}}</h3>
        <h3 class="py-16" *ngIf="!siteDetails">Site details & check in data will load once a site is selected</h3>
      </div>

      <!-- find & open new site -->
      <div [formGroup]="siteForm" id="checkin-form" [ngClass]="siteDetails ? 'checkin-form' : 'checkin-form-empty'">
        <mat-form-field [ngClass]="siteDetails ? 'site-search' : 'site-search-empty'" appearance="outline">
        <mat-label *ngIf="siteDetails">Find Another Site</mat-label>
        <mat-label *ngIf="!siteDetails">Find A Site</mat-label>
        <input type="text"
            placeholder="Site Name"
            aria-label="Site"
            matInput
            formControlName="location"
            (input)="onSiteFormInput()"
            ng-model-options="{debounce: 1000}"
            [matAutocomplete]="autoSite">
        <mat-autocomplete #autoSite="matAutocomplete">
          <mat-option #siteOption
              *ngFor="let site of sites"
              [value]="site.siteAbbreviation"
              (click)="openSite(siteOption.value)">
            {{site.siteAbbreviation}} ({{site.siteName}})
          </mat-option>
        </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <br>

  <!-- area for current check ins --> 
    <!-- current check ins loading overlay --> 
    <div *ngIf="siteDetails" class="processing-overlay loading-overlay loader-large bg-white" [style.display]="loadingOverlayDisplay">
      <de-loader></de-loader>
    </div>

    <!-- Error message if data fails to load --> 
    <div class="processing-overlay bg-white" [style.display]="tableError">
      <div class="flex justify-center py-32 text-red">
        <img src="assets/icons/status-bad.png" width="20" height="20" alt="bad" class="float-left mx-16 object-contain"> 
        Error loading Current Check Ins. Please refresh page or contact support if issue persists. 
      </div>
    </div>

    <!-- current check in block -->
    <div *ngIf="siteDetails" id="checkins-table" [style.display]="tableDisplay" class="bg-white">
      <h2 class="pt-8">Currently Checked In</h2>

      <!-- current check in search & sort -->
      <div class="table-options-container-active">

        <!-- current check in search control -->
        <div class="filter-wrapper">
          <mat-form-field appearance="standard">
            <input matInput id="filterInput" placeholder="Search" (keyup)="applyFilter()">
          </mat-form-field>
        </div>

        <!-- current check in sort for mobile views only -->
        <mat-form-field appearance="standard" class="mobile-sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="sortData($event.value)">
            <mat-option *ngFor="let sort of sortFields" [value]="{active: sort.active, direction: sort.direction}">
              {{sort.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- current check in table display -->
      <table matSort (matSortChange)="sortData($event)" class="w-full bg-white border-t border-gray-light">

        <!-- current checkin table header, columns with sorting feature -->
        <thead>
          <tr class="checkin-header hidden sm:table-row border-b border-gray-light font-bold">
            <th mat-sort-header="contractor" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Contractor
            </th>
            <th class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Contact #
            </th>
            <th mat-sort-header="purpose" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Purpose
            </th>
            <th mat-sort-header="checkinTime" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Check-In
            </th>
            <th class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Details
            </th>
          </tr>
        </thead>

        <!-- current check in table rows -->
        <tbody class="block sm:table-row-group">

          <!-- columnar display for tablet/desktop sizes -->
          <tr *ngFor="let checkin of checkinsFilteredAndPageSelectedList" (click)="entryClicked(checkin)"
              class="checkin-row grid sm:table-row grid-rows-auto grid-flow-col gap-y-8 gap-x-24 py-12 sm:py-0 border-b border-gray-light">
            <td class="flex sm:table-cell gap-12 sm:px-16 sm:py-12 align-middle sm:text-left">
              <strong class="flex-shrink-0 w-76">
                {{checkin.LastName}}, {{checkin.FirstName}}
              </strong>
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.EmpID}} (phone TBD)
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.VisitReason}}
            </td>
            <!-- if date is today, only show time. if date before today but still this year, show month day & time. if not this year, show full date time. -->
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <span *ngIf="checkin.CheckInTime.getTime() > yesterday.getTime()">{{checkin.CheckInTime | date: 'h:mm a' }}</span>
              <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() == thisYear">{{checkin.CheckInTime | date: 'MMM dd @ h:mm a' }}</span>
              <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() < thisYear">{{checkin.CheckInTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
            </td>
            <td class="expand-arrow row-span-3 flex sm:table-cell items-start justify-end sm:pl-32 py-6 sm:py-12 align-middle text-teal-darker">
              <img src= "assets/icons/popout.png" width="20" height="20" alt="open">
            </td>
            <hr class="border-gray mt-16"/>
          </tr>

          <!-- vertical card display for mobile/small tablet sizes -->
          <tr *ngFor="let checkin of checkinsFilteredAndPageSelectedList" (click)="entryClicked(checkin)"
              class="checkin-row-card active-card">
            <div class="float-left w-4/5 pt-8"> 
              <span class="font-bold text-teal-darker pl-8">{{checkin.LastName}}, {{checkin.FirstName}}</span>
            </div>
            <div class="float-right w-1/5 pr-16 pt-16">
              <img class="float-right" src= "assets/icons/popout.png" width="20" height="20" alt="open">
            </div>
            <div class="w-full">
              <span class="pl-16">
                <span class="font-bold">Contact: </span>123-456-7890
              </span>
              <br>
              <span class="pl-16">
                <span class="font-bold">Purpose: </span>{{checkin.VisitReason}}
              </span>
              <br>
              <!-- if date is today, only show time. if date before today but still this year, show month day & time. if not this year, show full date time. -->
              <span class="pl-16">
                <span class="font-bold">Check-In: </span>
                  <span *ngIf="checkin.CheckInTime.getTime() > yesterday.getTime()">{{checkin.CheckInTime | date: 'h:mm a' }}</span>
                  <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() == thisYear">{{checkin.CheckInTime | date: 'MMM dd @ h:mm a' }}</span>
                  <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() < thisYear">{{checkin.CheckInTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
              </span>
            </div>
            <hr class="border-gray mt-16"/>
          </tr>
        </tbody>
      </table>

      <!-- current check in pagination controls -->
      <mat-paginator class="mt-32" #paginatorCurrent
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true"
        (page)="sliceFilteredCheckinArrayForPagination()">
      </mat-paginator>
    </div>
  </div>

  <!-- area for alarms -- can be commented out for now -->
  <!-- <div *ngIf="siteDetails" class="custom-container-90">
    <hr class="border-gray my-64"/> -->

    <!-- loading overlay for alarms -->
    <!-- <div class="processing-overlay loading-overlay loader-large bg-white" [style.display]="loadingAlarmsOverlayDisplay">
      <de-loader></de-loader>
    </div> -->

    <!-- Error message if alarms fails to load --> 
    <!-- <div class="processing-overlay bg-white" [style.display]="tableAlarmsError">
      <div class="flex justify-center py-32 text-red">
        <img src="assets/icons/status-bad.png" width="20" height="20" alt="bad" class="float-left mx-16 object-contain"> 
        Error loading Alarms. Please refresh page or contact support if issue persists. 
      </div>
    </div> -->

    <!-- alarms block -->
    <!-- <div id="checkins-table" [style.display]="tableAlarmsDisplay" class="bg-white">
      <h2 class="pt-8">Alarms (coming soon)</h2> -->

      <!-- search and sort for alarms -->
      <!-- <div class="table-options-container-active"> -->

        <!-- search control for alarms -->
        <!-- <div class="filter-wrapper">
          <mat-form-field appearance="standard">
            <input matInput id="filterAlarmInput" placeholder="Search" (keyup)="applyAlarmsFilter()">
          </mat-form-field>
        </div> -->

        <!-- sort controls for alarms for mobile view only -->
        <!-- <mat-form-field appearance="standard" class="mobile-sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="sortAlarmData($event.value)">
            <mat-option *ngFor="let sort of sortAlarmFields" [value]="{active: sort.active, direction: sort.direction}">
              {{sort.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <!-- table for alarm data -->
      <!-- <table matSort (matSortChange)="sortAlarmData($event)" class="w-full bg-white border-t border-gray-light"> -->

        <!-- header columns for alarm data with mat sort feature-->
        <!-- <thead>
          <tr class="checkin-header hidden sm:table-row border-b border-gray-light font-bold">
            <th mat-sort-header="name" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Alarm Name
            </th>
            <th class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Alarm Type
            </th>
            <th mat-sort-header="status" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Status
            </th>
            <th mat-sort-header="onoff" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Off/On
            </th>
          </tr>
        </thead> -->

        <!-- table rows for alarm data -->
       <!--  <tbody class="block sm:table-row-group"> -->

          <!-- columnar display for tablet/desktop sizes -->
          <!-- <tr *ngFor="let alarm of alarmsFilteredAndPageSelectedList"
              class="checkin-row grid sm:table-row grid-rows-auto grid-flow-col gap-y-8 gap-x-24 py-12 sm:py-0 border-b border-gray-light">
            <td class="flex sm:table-cell gap-12 sm:px-16 sm:py-12 align-middle sm:text-left">
              <strong class="flex-shrink-0 w-76">
                Test
              </strong>
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              Temporary
            </td> -->
            <!-- variable image based on status -->
            <!-- <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <img *ngIf="alarm.status == 'Good'" class="float-left mr-8" src= "assets/icons/status-good.png" width="20" height="20" alt="good">
              <img *ngIf="alarm.status == 'Warning'" class="float-left mr-8" src= "assets/icons/status-warning.png" width="20" height="20" alt="warning">
              <img *ngIf="alarm.status == 'Bad'" class="float-left mr-8" src= "assets/icons/status-bad.png" width="20" height="20" alt="bad">
              {{alarm.status}}
            </td> -->
            <!-- mat toggle for on/off display -- this might be disabled if just for display and no functionality -->
            <!-- <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <mat-slide-toggle [(ngModel)]="alarm.value"></mat-slide-toggle>
            </td>
            <hr class="border-gray mt-16"/>
          </tr> -->

          <!-- vertical card display for mobile/small tablet sizes -->
          <!-- <tr *ngFor="let alarm of alarmsFilteredAndPageSelectedList" 
              class="checkin-row-card active-card">
            <div class="float-left w-4/5 pt-8"> 
              <span class="font-bold text-teal-darker pl-8">Test</span>
            </div> -->
            <!-- mat toggle for on/off display -- this might be disabled if just for display and no functionality-->
            <!-- <div class="float-right w-1/5 pr-16 pt-8">
              <span class="font-bold align-top">Off/On: </span>
              <mat-slide-toggle  [(ngModel)]="alarm.value"></mat-slide-toggle>
            </div>
            <div class="w-full">
              <span class="pl-16">
                <span class="font-bold">Type: </span>Temporary
              </span>
              <br> -->
              <!-- variable icon display by status value -->
              <!-- <span class="pl-16">
                <span class="font-bold">Status: </span>
                <img *ngIf="alarm.status == 'Good'" class="inline mx-8" src= "assets/icons/status-good.png" width="20" height="20" alt="good">
                <img *ngIf="alarm.status == 'Warning'" class="inline mx-8" src= "assets/icons/status-warning.png" width="20" height="20" alt="warning">
                <img *ngIf="alarm.status == 'Bad'" class="inline mx-8" src= "assets/icons/status-bad.png" width="20" height="20" alt="bad">
                {{alarm.status}}
              </span>
              <br>
            </div>
            <hr class="border-gray mt-32"/>
          </tr>
        </tbody>
      </table> -->

      <!-- alarm pagination controls -->
      <!-- <mat-paginator class="mt-32" #paginatorAlarms
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true"
        (page)="sliceFilteredAlarmsArrayForPagination()">
      </mat-paginator>
    </div>
  </div> -->

  <!-- area for check in history -->
  <div *ngIf="siteDetails" class="custom-container-90">
    <hr class="border-gray my-64"/>

    <!-- check in history loading overlay -->
    <div class="processing-overlay loading-overlay loader-large bg-white" [style.display]="loadingHistoryOverlayDisplay">
      <de-loader></de-loader>
    </div>

    <!-- Error message if history fails to load --> 
    <div class="processing-overlay bg-white" [style.display]="tableHistoryError">
      <div class="flex justify-center py-32 text-red">
        <img src="assets/icons/status-bad.png" width="20" height="20" alt="bad" class="float-left mx-16 object-contain"> 
        Error loading Check In History. Please refresh page or contact support if issue persists. 
      </div>
    </div>

    <!-- check in history block -->
    <div id="checkins-table" [style.display]="tableHistoryDisplay" class="bg-white">
      <h2 class="pt-8">Check In History</h2>
      
      <!-- search, sort & date range filter for check in history data -->
      <div class="table-options-container-completed">

        <!-- search controls for checkin history -->
        <div class="filter-wrapper">
          <mat-form-field appearance="standard">
            <input matInput id="filterHistoryInput" placeholder="Search" (keyup)="applyHistoryFilter()">
          </mat-form-field>
        </div>

        <!-- sort control for checkin history for mobile view only -->
        <mat-form-field appearance="standard" class="mobile-sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="sortHistoryData($event.value)">
            <mat-option *ngFor="let sort of sortFields" [value]="{active: sort.active, direction: sort.direction}">
              {{sort.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- date range controls for checkin history -->
        <mat-form-field appearance="standard" class="date-range">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onDateRangeChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
      
      <!-- table for check in history data -->
      <table matSort (matSortChange)="sortHistoryData($event)" class="w-full bg-white border-t border-gray-light">

        <!-- header columns for check in history w/ mat sort feature -->
        <thead>
          <tr class="checkin-header hidden sm:table-row border-b border-gray-light font-bold">
            <th mat-sort-header="contractor" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Contractor
            </th>
            <th class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Contact #
            </th>
            <th mat-sort-header="purpose" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Purpose
            </th>
            <th mat-sort-header="checkinTime" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Check-In
            </th>
            <th mat-sort-header="checkoutTime" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Check-Out
            </th>
            <th class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Details
            </th>
          </tr>
        </thead>

        <!-- table rows for check in history data -->
        <tbody class="block sm:table-row-group">

          <!-- columnar display for tablet/desktop sizes -->
          <tr *ngFor="let checkin of checkinsHistoryFilteredAndPageSelectedList" (click)="entryClicked(checkin)"
              class="checkin-row grid sm:table-row grid-rows-auto grid-flow-col gap-y-8 gap-x-24 py-12 sm:py-0 border-b border-gray-light">
            <td class="flex sm:table-cell gap-12 sm:px-16 sm:py-12 align-middle sm:text-left">
              <strong class="flex-shrink-0 w-76">
                {{checkin.LastName}}, {{checkin.FirstName}}
              </strong>
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.EmpID}} (phone TBD)
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.VisitReason}}
            </td>
            <!-- if date is today, only show time. if date before today but still this year, show month day & time. if not this year, show full date time. -->
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <span *ngIf="checkin.CheckInTime.getTime() > yesterday.getTime()">{{checkin.CheckInTime | date: 'h:mm a' }}</span>
              <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() == thisYear">{{checkin.CheckInTime | date: 'MMM dd @ h:mm a' }}</span>
              <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() < thisYear">{{checkin.CheckInTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <span *ngIf="checkin.CheckOutTime.getTime() > yesterday.getTime()">{{checkin.CheckOutTime | date: 'h:mm a' }}</span>
              <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() && checkin.CheckOutTime.getFullYear() == thisYear">{{checkin.CheckOutTime | date: 'MMM dd @ h:mm a' }}</span>
              <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() && checkin.CheckOutTime.getFullYear() < thisYear">{{checkin.CheckOutTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
            </td>
            <td class="expand-arrow row-span-3 flex sm:table-cell items-start justify-end sm:pl-32 py-6 sm:py-12 align-middle text-teal-darker">
              <img src= "assets/icons/popout.png" width="20" height="20" alt="open">
            </td>
            <hr class="border-gray mt-16"/>
          </tr>

           <!-- vertical card display for mobile/small tablet sizes -->
          <tr *ngFor="let checkin of checkinsHistoryFilteredAndPageSelectedList" (click)="entryClicked(checkin)"
              class="checkin-row-card completed-card">
            <div class="float-left w-4/5 pt-8"> 
              <span class="font-bold text-teal-darker pl-8">{{checkin.LastName}}, {{checkin.FirstName}}</span>
            </div>
            <div class="float-right w-1/5 pr-16 pt-16">
              <img class="float-right" src= "assets/icons/popout.png" width="20" height="20" alt="open">
            </div>
            <div class="w-full">
              <span class="pl-16">
                <span class="font-bold">Contact: </span>123-456-7890
              </span>
              <br>
              <span class="pl-16">
                <span class="font-bold">Purpose: </span>{{checkin.VisitReason}}
              </span>
              <br>
              <!-- if date is today, only show time. if date before today but still this year, show month day & time. if not this year, show full date time. -->
              <span class="pl-16">
                <span class="font-bold">Check-In: </span>
                  <span *ngIf="checkin.CheckInTime.getTime() > yesterday.getTime()">{{checkin.CheckInTime | date: 'h:mm a' }}</span>
                  <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() == thisYear">{{checkin.CheckInTime | date: 'MMM dd @ h:mm a' }}</span>
                  <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() < thisYear">{{checkin.CheckInTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
              </span>
              <br>
              <span class="pl-16">
                <span class="font-bold">Check-Out: </span>
                <span *ngIf="checkin.CheckOutTime.getTime() > yesterday.getTime()">{{checkin.CheckOutTime | date: 'h:mm a' }}</span>
                <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() && checkin.CheckOutTime.getFullYear() == thisYear">{{checkin.CheckOutTime | date: 'MMM dd @ h:mm a' }}</span>
                <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() && checkin.CheckOutTime.getFullYear() < thisYear">{{checkin.CheckOutTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
              </span>
            </div>
            <hr class="border-gray mt-16"/>
          </tr>
        </tbody>
      </table>

      <!-- check in history pagination control-->
      <mat-paginator class="mt-32" #paginatorHistory
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true"
        (page)="sliceFilteredCheckinHistoryArrayForPagination()">
      </mat-paginator>
    </div>
  </div>
</section>
