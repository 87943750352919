import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppConfig } from './config/app.config';
import { apiConfig, environments, msalInitialConfig, protectedResourcesConfig, protectedResourcesConfigSbxAndLocal } from 'src/config/env.config';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DeButtonModule, DeFooterModule, DeHeaderModule, DeLoaderModule, DeSnackbarModule } from '@de-electron/electron-angular-components';
import { NocOverviewComponent } from './components/noc-overview/noc-overview.component';
import { AppRoutingModule } from './app-routing.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserCheckinComponent } from './components/user-checkin/user-checkin.component';
import { UserCheckinsComponent } from "./components/user-checkins/user-checkins.component";
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { PublicClientApplication, InteractionType, IPublicClientApplication } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalModule,
  MsalGuardConfiguration,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService
} from '@azure/msal-angular';
import { CheckinDialogComponent } from "./components/checkin/checkin-dialog.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { CheckinDetailsComponent } from './components/checkin-details/checkin-details.component';
import { MatIconModule } from "@angular/material/icon";
import { NocSiteComponent } from './components/noc-site/noc-site.component';
import { WorkerDetailsComponent } from './components/worker-details/worker-details.component';
import { PhoneDisplayPipe } from 'src/app/pipes/phone-number.pipe';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { NOCGuard } from './services/authentication/noc-guard.guard';
import { B2CTokenClaims } from './models/B2CTokenClaims';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
//import { LoginComponent } from './components/login/login.component';
import { env } from 'src/config/env';

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalInitialConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  console.log('switch(environment): ',environment.env);
  const protectedResourceMap = environment.env =='local'|| environment.env == 'sbx'? protectedResourcesConfigSbxAndLocal:protectedResourcesConfig;

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...apiConfig.scopes],
    },
  };
}

export function loadConfigurations(appConfig: AppConfig) {
  return () => {
    appConfig.load();
  };
}
@NgModule({
  declarations: [
    AppComponent,
    NocOverviewComponent,
    NocSiteComponent,
    UserCheckinComponent,
    UserCheckinsComponent,
    CheckinDialogComponent,
    CheckinDetailsComponent,
    WorkerDetailsComponent,
    ConfirmActionComponent,
    PhoneDisplayPipe,
 //   LoginComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DeButtonModule,
    DeFooterModule,
    DeHeaderModule,
    DeLoaderModule,
    DeSnackbarModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    MsalModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  providers: [
    AppConfig,
    { provide: 'environments', useValue: environments },
    { provide: 'env', useValue: env },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      deps: [AppConfig],
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NOCGuard,
    AppConfig,
    {
      provide: MatDialogRef,
      useValue: {}
    },

    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: getUseFactory,
    //   deps: [AppConfig],
    //   multi: true
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: getNOCFactory, 
    //   deps: [AppConfig],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }

// export function getUseFactory(config: AppConfig) {
//   return () => config.load();
// }

// export function getNOCFactory(config: AppConfig) {
//   return () => config.loadNOC();
// }