<de-header [isExternal]="false" [headerLinks]="headerLinks" (navigation)="onNavigate($event)" siteTitle="Telecom Site Access">
  <span *ngIf="!mobileMenuOpen" (click)="toggleMenu()" class="mobile-menu">&#9776;</span>
  <span *ngIf="mobileMenuOpen" (click)="toggleMenu()" class="mobile-menu">&#9587;</span>
  <div class="mobile-menu-links" *ngIf="mobileMenuOpen">
    <ul>
      <li *ngFor="let link of headerLinks" (click)="onNavigate(link)" class="flex-auto">
        <span (click)="onNavigate(link)" class="align-top">{{link.viewValue}}</span>
        <img (click)="onNavigate(link)" src= {{link.icon}} width="30" height="30" alt={{link.viewValue}}>
      </li>
      <de-footer [showSlogan]="false" class="footer-override"></de-footer>
    </ul>
  </div>
</de-header>


<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>

<div class="main-container bg-gray-lighter">
    <router-outlet></router-outlet>
</div>


<de-footer [showSlogan]="false"></de-footer>
