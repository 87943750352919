import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MsalGuardConfiguration, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfig } from "../config/app.config";
import { CheckinRequest } from "../models/checkin-request.model";
import { KeyValuePair } from "../models/key-value-pair.model";


@Injectable({
  providedIn: 'root',
})
export class TelaxsCallsService {
  baseURL: string;
  getSitesURL: string;
  httpHeaders: HttpHeaders;
  httpOptions: Object = { observe: 'response' };

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private httpClient: HttpClient,
    private appConfig: AppConfig
  ) {
    this.baseURL = this.appConfig.getConfig('telaxsBaseURL');
    this.getSitesURL = this.appConfig.getConfig('insiteBaseURL');
  }

  private buildHttpOptionsWithParams(
    httpOptions: Object,
    ...params: KeyValuePair[]
  ) {
    let httpParams = new HttpParams();
    params.forEach((param) => {
      httpParams = httpParams.append(param.key, param.value);
    });
    let getOptions = Object.assign([], httpOptions); // clones the array
    getOptions['params'] = httpParams;
    return getOptions;
  }

  private buildHttpOptionsWithHeaders() {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
    });

    this.httpOptions['headers'] = this.httpHeaders;
    return this.httpOptions;
  }


  private buildNAPSHttpOptionsWithHeaders() {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    this.httpOptions['headers'] = this.httpHeaders;
    return this.httpOptions;
  }

  /*
   *  ADMIN
   */

  public getActiveCheckins(): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    this.httpOptions = headers;

    return this.httpClient.get(
      `${this.baseURL}/admin/global/checkins/active`,
      this.httpOptions
    );
  }

  public getCompletedCheckins(startTime, endTime): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    let startTimeParam = new KeyValuePair('startTime', startTime);
    let endTimeParam = new KeyValuePair('endTime', endTime);
    let getOptions: Object = this.buildHttpOptionsWithParams(
      headers,
      startTimeParam,
      endTimeParam
    );

    return this.httpClient.get(
      `${this.baseURL}/admin/global/checkins/completed`,
      getOptions
    );
  }

  public getActiveCheckinsBySite(siteID): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    let siteIDParam = new KeyValuePair('siteID', siteID);
    let getOptions: Object = this.buildHttpOptionsWithParams(
      headers,
      siteIDParam
    );

    return this.httpClient.get(
      `${this.baseURL}/admin/checkins/active`,
      getOptions
    );
  }

  public getCompletedCheckinsBySite(
    siteID,
    startTime,
    endTime
  ): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    let siteIDParam = new KeyValuePair('siteID', siteID);
    let startTimeParam = new KeyValuePair('startTime', startTime);
    let endTimeParam = new KeyValuePair('endTime', endTime);
    let getOptions: Object = this.buildHttpOptionsWithParams(
      headers,
      siteIDParam,
      startTimeParam,
      endTimeParam
    );

    return this.httpClient.get(
      `${this.baseURL}/admin/checkins/completed`,
      getOptions
    );
  }

  /*
   *  INSITE
   */

  public getSitesBySearchTerm(term: String): Observable<any> {
    let headers: Object = this.buildNAPSHttpOptionsWithHeaders();
    let typeParam = new KeyValuePair("type", "location");
    let termParam = new KeyValuePair("term", term);  // TODO sanitize
    let externalParam = new KeyValuePair("external", "true");
    let getOptions: Object = this.buildHttpOptionsWithParams(headers, typeParam, termParam, externalParam);
    let url: string = `${this.baseURL}` + '/worker/naps/locations?searchString=' + term;

    // return this.httpClient.get(`${this.getSitesURL}`, getOptions);
    return this.httpClient.get(url, headers);

  }

  /*
   *  USER
   */

  public checkinUser(checkin: CheckinRequest): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    this.httpOptions = headers;

    return this.httpClient.post(
      `${this.baseURL}/worker/checkin`,
      checkin,
      this.httpOptions
    );
  }

  public getWorkerActiveCheckins(workerID: string): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    let workerIDParam = new KeyValuePair('workerID', workerID);
    let getOptions: Object = this.buildHttpOptionsWithParams(
      headers,
      workerIDParam
    );

    return this.httpClient.get(
      `${this.baseURL}/worker/checkins/active`,
      getOptions
    );
  }

  public getWorkerCompletedCheckins(
    workerID: string,
    startTime,
    endTime
  ): Observable<any> {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    let startTimeParam = new KeyValuePair('startTime', startTime);
    let endTimeParam = new KeyValuePair('endTime', endTime);
    let workerIDParam = new KeyValuePair('workerID', workerID);
    let getOptions: Object = this.buildHttpOptionsWithParams(
      headers,
      workerIDParam,
      startTimeParam,
      endTimeParam
    );

    return this.httpClient.get(
      `${this.baseURL}/worker/checkins/completed`,
      getOptions
    );
  }

  public checkout(checkinID: number) {
    let headers: Object = this.buildHttpOptionsWithHeaders();
    this.httpOptions = headers;

    return this.httpClient.put(
      `${this.baseURL}/worker/checkout`,
      { CheckinID: checkinID },
      this.httpOptions
    );
  }
}
