import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CheckinEntry } from 'src/app/models/checkin-entry.model';
import { TelaxsCallsService } from 'src/app/services/telaxs-calls.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {EventMessage, EventType, InteractionStatus} from "@azure/msal-browser";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {CheckinDetailsComponent} from "../checkin-details/checkin-details.component";
import {MatDialog} from "@angular/material/dialog";
import { CheckinSite } from 'src/app/models/checkin-site.model';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';
import { UserProfile } from 'src/app/models/UserProfile';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export interface CheckinDetails {
  ID: string,
  siteName: string;
  checkinTime: Date;
};

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

interface SortField {
  active: string;
  viewValue: string;
  direction: string;
}

@Component({
  selector: 'app-noc-overview',
  templateUrl: './noc-overview.component.html',
  styleUrls: ['./noc-overview.component.scss']
})

export class NocOverviewComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  // define tailwinds classes here to make ngClass less awful looking in html?
  ACTIVE_TAB_CLASS = "bg-white relative flex flex-col flex-shrink-0 items-center w-1/2 p-8 md:px-12 lg:px-24 hover:bg-teal-lighter text-center font-bold text-teal-darker transition-colors duration-150";
  ACTIVE_TAB_BORDER_CLASS = "absolute bottom-0 block w-full h-4 bg-teal-dark scale-100 transform transition-all duration-200";
  INACTIVE_TAB_CLASS = "relative flex flex-col flex-shrink-0 items-center w-1/2 p-8 md:px-12 lg:px-24 hover:bg-teal-lighter text-center text-teal-darker transition-colors duration-150";
  INACTIVE_TAB_BORDER_CLASS = "absolute bottom-0 block w-full h-4 bg-teal-dark scale-0 transform transition-all duration-200";

  // set up some date/time variables for formatting & filtering
  DEFAULT_DAYS_IN_PAST_FOR_COMPLETED_CHECKINS = 7;
  startTimeStamp = null;
  endTimeStamp = null;
  yesterday = new Date();
  thisYear = new Date().getFullYear();

  // set up vars for user checkin data -- used for both active and completed
  dataSource: MatTableDataSource<CheckinEntry>;
  checkinsMasterList: CheckinEntry[];
  checkinsFilteredList: CheckinEntry[];
  checkinsFilteredAndPageSelectedList: CheckinEntry[];

  // define sort options for dropdown when in mobile view
  sortFields: SortField[] = [
    {active: 'n/a', viewValue: 'N/A', direction: 'asc'},
    {active: 'siteName', viewValue: 'Site Name (A-Z)', direction: 'asc'},
    {active: 'siteName', viewValue: 'Site Name (Z-A)', direction: 'desc'},
    {active: 'checkinTime', viewValue: 'Check In (Newest)', direction: 'desc'},
    {active: 'checkinTime', viewValue: 'Check In (Oldest)', direction: 'asc'},
    {active: 'contractor', viewValue: 'Contactor (A-Z)', direction: 'asc'},
    {active: 'contractor', viewValue: 'Contactor (Z-A)', direction: 'desc'}
  ];

  // list of sites to display in find site control
  sites: CheckinSite[] = [];

  // form to find & open site
  siteForm = new FormGroup({
    location: new FormControl('')
  })

  // for check in date/time filtering
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  // init other default vals
  profile!: ProfileType;
  loadingOverlayDisplay = 'inherit';
  tableDisplay = 'none';
  tableError = 'none';
  curTab = 'active';
  isAdmin = false;
  isLead: false;
  lastFilterVal: string = ''; // filter memory, affects pagination behavior
  pageSize = 10;
  memberOfNOC: any;

  constructor(private router: Router,
    private service: TelaxsCallsService,
    private authService: MsalService,
    private authNOC: AuthenticationService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    public dialog: MatDialog) {

  }

  ngOnInit() {

    // does login stuff
    // this.msalBroadcastService.msalSubject$
    //   .pipe(
    //     filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //   )
    //   .subscribe((result: EventMessage) => {
    //     console.log(result);
    //   });

    // this.msalBroadcastService.inProgress$
    //   .pipe(
    //     filter((status: InteractionStatus) => status === InteractionStatus.None)
    //   )
    //   .subscribe(() => {
    //     this.setLoginDisplay();
    //   })


    // init yesterday for date display
    this.yesterday.setDate(this.yesterday.getDate()-1);

    // init today & last week for default check in time filter range
    let today = new Date();
    today.setHours(23, 59, 59, 0);
    let oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(oneWeekAgo.getDate()-this.DEFAULT_DAYS_IN_PAST_FOR_COMPLETED_CHECKINS);

    // set default range to controls
    this.range.controls.start.setValue(oneWeekAgo);
    this.range.controls.end.setValue(today);
    this.startTimeStamp = oneWeekAgo.getTime()/1000;
    this.endTimeStamp = today.getTime()/1000;

    // get user profile info
    this.getProfile();
    this.confirmUserRole();
  }

  getProfile() {
    // hide table data/show loading overlay until profile retrieved -- then get active checkins
    this.loadingOverlayDisplay = 'inherit';
    this.tableDisplay = 'none';
    // this.http.get(GRAPH_ENDPOINT)
    //   .subscribe(profile => {
    //     this.profile = profile;
    //   },
    //   () => {},
    //   () => {
    //     console.log("Finished getting profile")
    //     this.loadingOverlayDisplay = 'none';
    //     this.tableDisplay = 'inherit';

    //     this.getActiveCheckinTableData();
    //   });

      this.profile = this.authService.instance.getActiveAccount()?.idTokenClaims as UserProfile;
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'inherit';
      this.getActiveCheckinTableData();

  }

  confirmUserRole() {
    // determines whether to show sign in or sign out menu item
    // this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
     this.authNOC.confirmNOC().subscribe((data)=>{
      this.isAdmin = data.admin
      this.isLead = data.lead
    })
  }

  // open checkin details in noc view
  entryClicked(entry: CheckinEntry) {
    this.dialog.open(CheckinDetailsComponent, {
      data: {entry: entry, role: this.isLead ? "lead" : "noc"}
    }).afterClosed().subscribe(res => {
      // if user checked out, then re-get active checkins
      if (res == 'CHECKED OUT'){
        this.getActiveCheckinTableData()
      }
    });
  }

  onSiteFormInput() {
    let term = this.siteForm.controls.location.value;
    if (term.length > 1) {
      this.service
        .getSitesBySearchTerm(term)
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((res) => {
          this.sites = res.body.slice(0, 19);
        });
    }
  }

  openSite(site) {
    // open selected site in new tab with site as window name
    var baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + "/nocSite?abrv=" + site, site);
  }

  // apply filter to check in data
  applyFilter() {
    let filterVal = (<HTMLInputElement> document.getElementById("filterInput")).value;

    // populate the filtered array
    this.dataSource.filter = filterVal;
    if (!filterVal) {
        // only filter by date range if completed check in tab selected
        if (this.startTimeStamp != null && this.endTimeStamp != null && this.curTab == 'completed') {
          var newFilteredArr: Array<CheckinEntry> = [];
          this.checkinsMasterList.forEach(entry => {
            if (Date.parse(entry.CheckInTime.toDateString())/1000 >= this.startTimeStamp && Date.parse(entry.CheckInTime.toDateString())/1000 <= this.endTimeStamp) {
              newFilteredArr.push(entry);
            }
          })
          this.checkinsFilteredList = newFilteredArr;
        } else { // return all
          this.checkinsFilteredList = this.checkinsMasterList;
        }

    } else {
      // first filter by search value
      filterVal = filterVal.toLowerCase();
      var newFilteredArr: Array<CheckinEntry> = [];
      this.checkinsMasterList.forEach(entry => {
        try {
          if (entry.EmpID.toLowerCase().includes(filterVal)
                || entry.PhoneNumber.toLowerCase().includes(filterVal)
                || entry.FirstName.toLowerCase().includes(filterVal)
                || entry.LastName.toLowerCase().includes(filterVal)
                || entry.VisitReason.toLowerCase().includes(filterVal)
                || entry.Organization.toLowerCase().includes(filterVal)
                || entry.SiteID.toLowerCase().includes(filterVal)) {
            newFilteredArr.push(entry);
          }
        } catch (e) {
          //  ignore
          }
        });
      this.checkinsFilteredList = newFilteredArr;

      // next filter for date range
      if (this.startTimeStamp != null && this.endTimeStamp != null && this.curTab == 'completed') {
        var newFilteredArrDates: Array<CheckinEntry> = [];
        this.checkinsFilteredList.forEach(entry => {
          if (Date.parse(entry.CheckInTime.toDateString())/1000 >= this.startTimeStamp && Date.parse(entry.CheckInTime.toDateString())/1000 <= this.endTimeStamp) {
            newFilteredArrDates.push(entry);
          }
        })
        this.checkinsFilteredList = newFilteredArrDates;
      }
    }

    // set up paginator
    if (this.dataSource.paginator) {
        this.dataSource.data = this.checkinsFilteredList;
        if (filterVal != this.lastFilterVal) {
            this.dataSource.paginator.firstPage();
        }
        this.dataSource.paginator.length = this.checkinsFilteredList.length;
    }
    this.lastFilterVal = filterVal;

    // finish pagination
    this.sliceFilteredCheckinArrayForPagination();
  }

  getActiveCheckinTableData() {

    this.loadingOverlayDisplay = 'inherit';
    this.tableDisplay = 'none';
    this.tableError = 'none';
    var tzOffset = new Date().getTimezoneOffset();
    this.service.getActiveCheckins().subscribe((res: HttpResponse<any>) => {
      this.checkinsMasterList = res.body;

      // convert Date strings to actual dates bc thats what I set up the html to work with
      this.checkinsMasterList.forEach(c => {
        var tempCheckInTime = new Date(c.CheckInTime);
        c.CheckInTime = new Date(tempCheckInTime.setMinutes(tempCheckInTime.getMinutes() - tzOffset));
      })

      this.checkinsFilteredList = this.checkinsMasterList;
      this.dataSource = new MatTableDataSource<CheckinEntry>(this.checkinsFilteredList);
    }, () => {
      console.error("Failed to load check-ins");
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'none';
      this.tableError = 'inherit';
    }, () => {
      console.log("Finished getting active checkins")
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'inherit';
      this.dataSource.paginator = this.paginator;
      this.applyFilter();
    })
  }

  getCompletedCheckinTableData() {

    this.loadingOverlayDisplay = 'inherit';
    this.tableDisplay = 'none';
    this.tableError = 'none';
    var tzOffset = new Date().getTimezoneOffset();
    this.service.getCompletedCheckins(this.startTimeStamp, this.endTimeStamp).subscribe((res: HttpResponse<any>) => {
      this.checkinsMasterList = res.body;

      // convert Date strings to actual dates bc thats what I set up the html to work with
      this.checkinsMasterList.forEach(c => {
        var tempCheckInTime = new Date(c.CheckInTime);
        c.CheckInTime = new Date(tempCheckInTime.setMinutes(tempCheckInTime.getMinutes() - tzOffset));
        if(c.CheckOutTime != null){
          var tempCheckOutTime = new Date(c.CheckOutTime);
          c.CheckOutTime = new Date(tempCheckOutTime.setMinutes(tempCheckOutTime.getMinutes() - tzOffset));
        }
      })

      this.checkinsFilteredList = this.checkinsMasterList;
      this.dataSource = new MatTableDataSource<CheckinEntry>(this.checkinsFilteredList);
    }, () => {
      console.error("Failed to load check-ins");
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'none';
      this.tableError = 'inherit';
    }, () => {
      console.log("Finished getting completed checkins")
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'inherit';
      this.dataSource.paginator = this.paginator;
      this.applyFilter();
    })
  }

  // from the filtered array, populate the subarray with the items on the given page
  sliceFilteredCheckinArrayForPagination() {
      let startIdx = this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
      let endIdx = Math.min((this.dataSource.paginator.pageSize * (this.dataSource.paginator.pageIndex + 1)),
              this.checkinsFilteredList.length);
      this.checkinsFilteredAndPageSelectedList = this.checkinsFilteredList.slice(startIdx, endIdx);
      this.loadingOverlayDisplay = 'none';
  }

  // sort according to field & order passed in from html
  // if no field provided, re-get master list as default order
  sortData(sort: Sort) {
    var data = null;
    if (!sort.active || sort.direction === '') {
      data = this.checkinsMasterList;
    } else {
      data = this.checkinsMasterList.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'siteName':
            return compare(a.SiteID, b.SiteID, isAsc);
          case 'contractor':
            if (a.LastName == b.LastName) {
              return compare(a.FirstName, b.FirstName, isAsc);
            } else {
              return compare(a.LastName, b.LastName, isAsc);
            }
          case 'purpose':
            return compare(a.VisitReason, b.VisitReason, isAsc);
          case 'checkinTime':
            return compare(a.CheckInTime, b.CheckInTime, isAsc);
          default:
            if(this.curTab == 'active'){
              this.getActiveCheckinTableData();
            } else {
              this.getCompletedCheckinTableData();
            }
            return 0;
        }
      });
    }

    // once sorted, reapply filter
    this.applyFilter();
  }

  // set up check in time filter dates & re-get completed data
  onDateRangeChange() {
    let newStart = this.range.controls.start.value;
    let newEnd = this.range.controls.end.value;

    if (newStart != null && newEnd != null) {
      this.startTimeStamp = (Date.parse(newStart)/1000);
      this.endTimeStamp = (Date.parse(newEnd)/1000) + 86349;
      this.loadingOverlayDisplay = 'inherit';
      this.tableDisplay = 'none';
      this.getCompletedCheckinTableData();
    } else {
      // if range incomplete or empty, revert to no date filter
      this.startTimeStamp = null;
      this.endTimeStamp = null;
      this.getCompletedCheckinTableData();
    }
  }

  // toggle between active and completed tables
  onCheckinTypeSelected(buttonType: string) {
    if (buttonType != this.curTab) {
      this.curTab = buttonType;
      this.loadingOverlayDisplay = 'inherit';
      this.tableDisplay = 'none';
      this.checkinsMasterList = new Array<CheckinEntry>();
      this.checkinsFilteredList = new Array<CheckinEntry>();
      if (buttonType == 'active') {
        this.getActiveCheckinTableData();
      } else if (buttonType == 'completed') {
        this.getCompletedCheckinTableData();
      } else {
        console.error("Unknown button type " + buttonType);
      }
    }
  }

}

function compare(a, b, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
