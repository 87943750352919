<div class="main-container" id="seting-modal">

  <!-- loading overlay -->
  <div class="processing-overlay loading-overlay loader-large" [style.display]="loadingOverlayDisplay">
    <de-loader></de-loader>
  </div>

  <!-- close button in top left -->
  <div class="close-button h-24 custom-mtl-neg-5" (click)="close()">
    <div class="mat-icon-button">
      <mat-icon><img src="assets/icons/x.png" width="20" height="20" alt="close"></mat-icon>
    </div>
  </div>

  <!-- confirmation message -->
  <h2 class="text-center font-bold text-teal-darker">
    {{confirmHeader}}
  </h2> 
  <br>
  <h3 class="text-center">
    {{confirmSubheader}}
  </h3> 
  <br>

  <div class="message-btns mx-auto flex relative justify-center flex-wrap" >
    <!-- cancel button -->
    <de-button type="secondary" class="content-center" (click) = "close()">CANCEL</de-button>
    <!-- confirm button -->
    <de-button type="submit" class="content-center" (click) = "confirm()">{{confirmButton}}</de-button>
  </div>

</div>
