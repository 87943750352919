<div class="main-container" id="seting-modal">
  <!-- process overlay -->
  <div class="processing-overlay loading-overlay loader-large" [style.display]="loadingOverlayDisplay">
    <de-loader></de-loader>
  </div>

  <!-- close button in tp left corner-->
  <div class="close-button h-24 custom-mtl-neg-5" (click)="close()">
    <div class="mat-icon-button">
      <mat-icon><img src="assets/icons/x.png" width="20" height="20" alt="close"></mat-icon>
    </div>
  </div>

  <!-- card header data in top center -->
  <h2 class="text-center font-bold text-teal-darker">{{workerData.lastName}}, {{workerData.firstName}}</h2>
  <h3 class="text-center" >Last Check In @ {{workerData.lastCheckin | date: 'MMM dd, yyyy @ h:mm a'}}</h3>
  <br>

  <!-- top container for Contact Info -->
  <div class="request-container flex-box de-component de-component--gray">
    <h3 class="text-2xl text-teal-darker mb-32">Contact Info</h3>
    <div class="content text-xl">
      <label for="phone" class="font-bold" >Phone:</label>
        <p id="phone" class="custom-mb-5 custom-mt-5">{{workerData.defaultPhoneNum | phoneDisplay}}</p>
      <hr class="text-gray mt-24 mb-16">
      <label for="id" class="font-bold">Employee ID:</label>
        <p id="id" class="custom-mb-5 custom-mt-5">{{workerData.id}}</p>
      <hr class="text-gray mt-24 mb-16">
      <label for="org" class="font-bold">Organization:</label>
        <p id="org" class="custom-mb-5 custom-mt-5">{{workerData.organization}}</p>
    </div>
  </div>
  <br>

  <!-- bottom container for Notes -->
  <div class="request-container flex-box de-component de-component--gray">
    <h3 class="text-2xl text-teal-darker mb-32">Notes</h3>
    <div class="content text-xl">
        <p id="notes" class="custom-mb-5 custom-mt-5">{{workerData.notes}}</p>
    </div>
  </div>
  
</div>
