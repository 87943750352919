import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckinEntry } from 'src/app/models/checkin-entry.model';
import { TelaxsCallsService } from 'src/app/services/telaxs-calls.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {filter} from "rxjs/operators";
import {EventMessage, EventType, InteractionStatus} from "@azure/msal-browser";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {MatDialog} from "@angular/material/dialog";
import { CheckinSite } from 'src/app/models/checkin-site.model';
import { WorkerDetailsComponent } from '../worker-details/worker-details.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';



const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
export interface CheckinDetails {
  ID: string,
  siteName: string;
  checkinTime: Date;
}
type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};
interface SortField {
  active: string;
  viewValue: string;
  direction: string;
}
@Component({
  selector: 'app-noc-site',
  templateUrl: './noc-site.component.html',
  styleUrls: ['./noc-site.component.scss']
})
export class NocSiteComponent implements OnInit {

  // pagintors for each table display
  @ViewChild('paginatorCurrent') paginatorCurrent: MatPaginator;
  @ViewChild('paginatorAlarms') paginatorAlarms: MatPaginator;
  @ViewChild('paginatorHistory') paginatorHistory: MatPaginator;

  // other default inits
  DEFAULT_DAYS_IN_PAST_FOR_COMPLETED_CHECKINS = 7;
  profile!: ProfileType;
  loginDisplay = false;
  yesterday = new Date();
  thisYear = new Date().getFullYear();

  // inits for current check in data
  loadingOverlayDisplay = 'inherit';
  tableDisplay = 'none';
  tableError = 'none';
  dataSource: MatTableDataSource<CheckinEntry>;
  checkinsMasterList: CheckinEntry[];
  checkinsFilteredList: CheckinEntry[];
  checkinsFilteredAndPageSelectedList: CheckinEntry[];

  // inits for check in history data
  loadingHistoryOverlayDisplay = 'inherit';
  tableHistoryDisplay = 'none';
  tableHistoryError = 'none';
  dataSourceHistory: MatTableDataSource<CheckinEntry>;
  checkinsHistoryMasterList: CheckinEntry[];
  checkinsHistoryFilteredList: CheckinEntry[];
  checkinsHistoryFilteredAndPageSelectedList: CheckinEntry[];
  startTimeStamp = null;
  endTimeStamp = null;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  // inits shared between current & historical check in data
  sortFields: SortField[] = [
    {active: 'n/a', viewValue: 'N/A', direction: 'asc'},
    {active: 'checkinTime', viewValue: 'Check In (Newest)', direction: 'desc'},
    {active: 'checkinTime', viewValue: 'Check In (Oldest)', direction: 'asc'},
    {active: 'contractor', viewValue: 'Contactor (A-Z)', direction: 'asc'},
    {active: 'contractor', viewValue: 'Contactor (Z-A)', direction: 'desc'}
  ];

  // inits for alarms data
  loadingAlarmsOverlayDisplay = 'inherit';
  tableAlarmsDisplay = 'none';
  tableAlarmsError = 'none';
  dataSourceAlarms: MatTableDataSource<any>;
  alarmsMasterList: any[];
  alarmsFilteredList: any[];
  alarmsFilteredAndPageSelectedList: any[];

  // inits shared between current & historical check in data
  sortAlarmFields: SortField[] = [
    {active: 'n/a', viewValue: 'N/A', direction: 'asc'},
    {active: 'name', viewValue: 'Contactor (A-Z)', direction: 'desc'},
    {active: 'name', viewValue: 'Contactor (Z-A)', direction: 'asc'},
    {active: 'status', viewValue: 'Status (A-Z)', direction: 'asc'},
    {active: 'status', viewValue: 'Status (Z-A)', direction: 'desc'},
    {active: 'onoff', viewValue: 'On', direction: 'asc'},
    {active: 'onoff', viewValue: 'Off', direction: 'desc'},
  ];

  // allows search & open of new site
  siteForm = new FormGroup({
    location: new FormControl('')
  })

  // list of site to get details from on load
  sites: CheckinSite[];

  // specific site details
  siteDetails: CheckinSite = null;

  lastFilterVal: string = ''; // filter memory, affects pagination behavior
  pageSize = 5;

  memberOfNOC: any;

  constructor(private router: Router,
    private service: TelaxsCallsService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    public dialog: MatDialog) {

    }

  ngOnInit() {
    // some login stuff
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

    // get site from query param
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var abrv = urlParams.get('abrv');

    if(abrv == '' || abrv == null){
      // update tab title to Find NOC Site
      document.title = "Find NOC Site";

      // set sitedetails to null
      this.siteDetails = null;

    } else {
      // update tab title for easier nav
      document.title = abrv + " - NOC Site";

      // set up for getSiteDetails()
      this.siteDetails = new CheckinSite;
      this.siteDetails.siteAbbreviation = abrv;
    }


    // init yesterday for date display
    this.yesterday.setDate(this.yesterday.getDate()-1);

    // init default date range
    let today = new Date();
    today.setHours(23, 59, 59, 0);
    let oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(oneWeekAgo.getDate()-this.DEFAULT_DAYS_IN_PAST_FOR_COMPLETED_CHECKINS);

    // set date range controls to default values
    this.range.controls.start.setValue(oneWeekAgo);
    this.range.controls.end.setValue(today);
    this.startTimeStamp = oneWeekAgo.getTime()/1000;
    this.endTimeStamp = today.getTime()/1000;

    // get user profile and retrieve data
    this.setupPageDetails();
  }

  setupPageDetails() {
    this.loadingOverlayDisplay = 'inherit';
    this.loadingHistoryOverlayDisplay = 'inherit';
    this.loadingAlarmsOverlayDisplay = 'inherit';
    this.tableDisplay = 'none';
    this.tableHistoryDisplay = 'none';
    this.tableAlarmsDisplay = 'none';
    if(this.siteDetails != null){
      this.getSiteDetails(this.siteDetails.siteAbbreviation)
    } else {
      this.loadingOverlayDisplay = 'none';
      this.loadingHistoryOverlayDisplay = 'none';
      this.loadingAlarmsOverlayDisplay = 'none';
      this.tableDisplay = 'inherit';
      this.tableHistoryDisplay = 'inherit';
      this.tableAlarmsDisplay = 'inherit';
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  entryClicked(id: string) {
    this.dialog.open(WorkerDetailsComponent, {
      data: {entry: id, parent: "noc"}
    })
  }

  getSiteDetails(siteAbbr) {

    if (siteAbbr.length > 1) {
      this.service
        .getSitesBySearchTerm(siteAbbr)
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((res) => {
          this.sites = res.body;

          if (this.sites.length > 0 && this.sites != null) {
            // return top 1 exact match from sites
            this.siteDetails = this.sites.filter((site) => {
              return site.siteAbbreviation === siteAbbr;
            })[0];
          } else {
            // throw a basic alert & else ensure null
            alert(
              'The site you are looking for does not exist. Try searching for a different one!'
            );
            // update url to remove bad abbrv
            this.router.navigateByUrl('/nocSite');
            // update tab title to Find NOC Site
            document.title = 'Find NOC Site';
            // set sitedetails to null
            this.siteDetails = null;
          }

          // get the site data
          this.loadingOverlayDisplay = 'none';
          this.loadingHistoryOverlayDisplay = 'none';
          this.loadingAlarmsOverlayDisplay = 'none';
          this.tableDisplay = 'inherit';
          this.tableHistoryDisplay = 'inherit';
          this.tableAlarmsDisplay = 'inherit';
          this.getActiveCheckinTableData();
          this.getCompletedCheckinTableData();
          //this.getAlarmsTableData();
        });
    }
  }

  onSiteFormInput() {
    let term = this.siteForm.controls.location.value;
    if (term.length > 1) {
      this.service
        .getSitesBySearchTerm(term)
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((res) => {
          this.sites = res.body.slice(0, 19);
        });
    }
  }

  openSite(site) {
    // if no site selected yet, then stay on current page & get site details & update tab name
    if(this.siteDetails == null) {
      this.getSiteDetails(site);
      // update tab title for easier nav
      document.title = site + " - NOC Site";
    } else {
      // open site in new tab so current will remain open
      var baseUrl = window.location.href.replace(this.router.url, '');
      window.open(baseUrl + "/nocSite?abrv=" + site, site);
    }
  }

  exit() {
    // if a site currently selected close whole site tab
    if (this.siteDetails != null) {
      window.close();
    } else {
      // nav back to NOC overview
      this.router.navigateByUrl('/noc');
    }

  }

  //**************************************************************************//
  // CODE FOR CURRENT CHECK IN DATA ******************************************//
  //**************************************************************************//

  getActiveCheckinTableData() {

    this.loadingOverlayDisplay = 'inherit';
    this.tableDisplay = 'none';
    this.tableError = 'none';
    var tzOffset = new Date().getTimezoneOffset();
    this.service.getActiveCheckinsBySite(this.siteDetails.siteAbbreviation).subscribe((res: HttpResponse<any>) => {
      this.checkinsMasterList = res.body;

      // convert Date strings to actual dates bc thats what I set up the html to work with
      // AND filter to current site
      var newFilteredArr: Array<CheckinEntry> = [];
      this.checkinsMasterList.forEach(c => {

        if (c.SiteID == this.siteDetails.siteAbbreviation) {
          var tempCheckInTime = new Date(c.CheckInTime);
          c.CheckInTime = new Date(tempCheckInTime.setMinutes(tempCheckInTime.getMinutes() - tzOffset));

          newFilteredArr.push(c);
        }
      })
      this.checkinsMasterList = newFilteredArr;

      this.checkinsFilteredList = this.checkinsMasterList;
      this.dataSource = new MatTableDataSource<CheckinEntry>(this.checkinsFilteredList);
    }, () => {
      console.error("Failed to load check-ins");
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'none';
      this.tableError = 'inherit';
    }, () => {
      console.log("Finished getting active checkins")
      this.loadingOverlayDisplay = 'none';
      this.tableDisplay = 'inherit';
      this.dataSource.paginator = this.paginatorCurrent;
      this.applyFilter();
    })
  }

  // Apply search filter for active checkins ALWAYS FILTER BY SITE
  applyFilter() {
    let filterVal = (<HTMLInputElement> document.getElementById("filterInput")).value;

    // populate the filtered array
    this.dataSource.filter = filterVal;
    if (!filterVal) {
      this.checkinsFilteredList = this.checkinsMasterList;
    } else {
        filterVal = filterVal.toLowerCase();
        var newFilteredArr: Array<CheckinEntry> = [];
        this.checkinsMasterList.forEach(entry => {
          try {
            if (entry.EmpID.toLowerCase().includes(filterVal)
                  || entry.FirstName.toLowerCase().includes(filterVal)
                  || entry.LastName.toLowerCase().includes(filterVal)
                  || entry.VisitReason.toLowerCase().includes(filterVal)
                  || entry.Organization.toLowerCase().includes(filterVal)) {
              newFilteredArr.push(entry);
            }
          } catch (e) {
            //  ignore
            }
          });
        this.checkinsFilteredList = newFilteredArr;
    }

    // set up current checkin paginator
    if (this.dataSource.paginator) {
        this.dataSource.data = this.checkinsFilteredList;
        if (filterVal != this.lastFilterVal) {
            this.dataSource.paginator.firstPage();
        }
        this.dataSource.paginator.length = this.checkinsFilteredList.length;
    }
    this.lastFilterVal = filterVal;

    // finish current check in pagination
    this.sliceFilteredCheckinArrayForPagination();
  }

  // sort current check in data by values passed from html controls
  // if values passed are null, re get active checkins to reset
  sortData(sort: Sort) {
    var data = null;
    if (!sort.active || sort.direction === '') {
      data = this.checkinsMasterList;
    } else {
      data = this.checkinsMasterList.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'siteName':
            return compare(a.SiteID, b.SiteID, isAsc);
          case 'contractor':
            if (a.LastName == b.LastName) {
              return compare(a.FirstName, b.FirstName, isAsc);
            } else {
              return compare(a.LastName, b.LastName, isAsc);
            }
          case 'purpose':
            return compare(a.VisitReason, b.VisitReason, isAsc);
          case 'checkinTime':
            return compare(a.CheckInTime, b.CheckInTime, isAsc);
          default:
            this.getActiveCheckinTableData();
            return 0;
        }
      });
    }

    // once sorted re-apply filter
    this.applyFilter();
  }

   // from the filtered current check in array, populate the subarray with the items on the given page
   sliceFilteredCheckinArrayForPagination() {
      let startIdx = this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex;
      let endIdx = Math.min((this.dataSource.paginator.pageSize * (this.dataSource.paginator.pageIndex + 1)),
              this.checkinsFilteredList.length);
      this.checkinsFilteredAndPageSelectedList = this.checkinsFilteredList.slice(startIdx, endIdx);
      this.loadingOverlayDisplay = 'none';
  }

   //**************************************************************************//
  // CODE FOR CHECK IN HISTORY DATA *******************************************//
  //**************************************************************************//

  getCompletedCheckinTableData() {

    this.loadingHistoryOverlayDisplay = 'inherit';
    this.tableHistoryDisplay = 'none';
    this.tableHistoryError = 'none';
    var tzOffset = new Date().getTimezoneOffset();
    this.service.getCompletedCheckinsBySite(this.siteDetails.siteAbbreviation, this.startTimeStamp, this.endTimeStamp).subscribe((res: HttpResponse<any>) => {
      this.checkinsHistoryMasterList = res.body;

      // convert Date strings to actual dates bc thats what I set up the html to work with
      this.checkinsHistoryMasterList.forEach(c => {
        var tempCheckInTime = new Date(c.CheckInTime);
        c.CheckInTime = new Date(tempCheckInTime.setMinutes(tempCheckInTime.getMinutes() - tzOffset));
        if(c.CheckOutTime != null){
          var tempCheckOutTime = new Date(c.CheckOutTime);
          c.CheckOutTime = new Date(tempCheckOutTime.setMinutes(tempCheckOutTime.getMinutes() - tzOffset));
        }
      })

      this.checkinsHistoryFilteredList = this.checkinsHistoryMasterList;
      this.dataSourceHistory = new MatTableDataSource<CheckinEntry>(this.checkinsHistoryFilteredList);
    }, () => {
      console.error("Failed to load check-ins");
      this.loadingHistoryOverlayDisplay = 'none';
      this.tableHistoryDisplay = 'none';
      this.tableHistoryError = 'inherit';
    }, () => {
      console.log("Finished getting completed checkins")
      this.loadingHistoryOverlayDisplay = 'none';
      this.tableHistoryDisplay = 'inherit';
      this.dataSourceHistory.paginator = this.paginatorHistory;
      this.applyHistoryFilter();
    })
  }

  // apply search & date range filters for check in history data
  applyHistoryFilter() {
    let filterVal = (<HTMLInputElement> document.getElementById("filterHistoryInput")).value;

    // populate the filtered array
    this.dataSource.filter = filterVal;

    if (!filterVal) {

      // only filter by date if range complete
      if (this.startTimeStamp != null && this.endTimeStamp != null) {
        var newFilteredArr: Array<CheckinEntry> = [];
        this.checkinsHistoryMasterList.forEach(entry => {
          if (Date.parse(entry.CheckInTime.toDateString())/1000 >= this.startTimeStamp && Date.parse(entry.CheckInTime.toDateString())/1000 <= this.endTimeStamp) {
            newFilteredArr.push(entry);
          }
        })
        this.checkinsHistoryFilteredList = newFilteredArr;
      } else { // return all
        this.checkinsHistoryFilteredList = this.checkinsHistoryMasterList;
      }

    } else {
      // first filter by search vaule
      filterVal = filterVal.toLowerCase();
      var newFilteredArr: Array<CheckinEntry> = [];
      this.checkinsHistoryMasterList.forEach(entry => {
        try {
          if (entry.EmpID.toLowerCase().includes(filterVal)
                || entry.FirstName.toLowerCase().includes(filterVal)
                || entry.LastName.toLowerCase().includes(filterVal)
                || entry.VisitReason.toLowerCase().includes(filterVal)
                || entry.Organization.toLowerCase().includes(filterVal)
                || entry.SiteID.toLowerCase().includes(filterVal)) {
            newFilteredArr.push(entry);
          }
        } catch (e) {
          //  ignore
          }
        });
      this.checkinsHistoryFilteredList = newFilteredArr;

      // next filter for date range
      if (this.startTimeStamp != null && this.endTimeStamp != null) {
        var newFilteredArrDates: Array<CheckinEntry> = [];
        this.checkinsHistoryFilteredList.forEach(entry => {
          if (Date.parse(entry.CheckInTime.toDateString())/1000 >= this.startTimeStamp && Date.parse(entry.CheckInTime.toDateString())/1000 <= this.endTimeStamp) {
            newFilteredArrDates.push(entry);
          }
        })
        this.checkinsHistoryFilteredList = newFilteredArrDates;
      }
    }

    // set up check in history paginator
    if (this.dataSourceHistory.paginator) {
        this.dataSourceHistory.data = this.checkinsHistoryFilteredList;
        if (filterVal != this.lastFilterVal) {
            this.dataSourceHistory.paginator.firstPage();
        }
        this.dataSourceHistory.paginator.length = this.checkinsHistoryFilteredList.length;
    }
    this.lastFilterVal = filterVal;

    // finish pagination for check in history
    this.sliceFilteredCheckinHistoryArrayForPagination();
  }

  // from the filtered array of check in history data, populate the subarray with the items on the given page
  sliceFilteredCheckinHistoryArrayForPagination() {
      let startIdx = this.dataSourceHistory.paginator.pageSize * this.dataSourceHistory.paginator.pageIndex;
      let endIdx = Math.min((this.dataSourceHistory.paginator.pageSize * (this.dataSourceHistory.paginator.pageIndex + 1)),
              this.checkinsHistoryFilteredList.length);
      this.checkinsHistoryFilteredAndPageSelectedList = this.checkinsHistoryFilteredList.slice(startIdx, endIdx);
      this.loadingHistoryOverlayDisplay = 'none';
  }

  // sort check in history data by values passed from html controls
  // if values passed are null, re get completed checkins to reset
  sortHistoryData(sort: Sort) {
    var data = null;
    if (!sort.active || sort.direction === '') {
      data = this.checkinsHistoryMasterList;
    } else {
      data = this.checkinsHistoryMasterList.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'siteName':
            return compare(a.SiteID, b.SiteID, isAsc);
          case 'contractor':
            if (a.LastName == b.LastName) {
              return compare(a.FirstName, b.FirstName, isAsc);
            } else {
              return compare(a.LastName, b.LastName, isAsc);
            }
          case 'purpose':
            return compare(a.VisitReason, b.VisitReason, isAsc);
          case 'checkinTime':
            return compare(a.CheckInTime, b.CheckInTime, isAsc);
          default:
            this.getCompletedCheckinTableData();
            return 0;
        }
      });
    }

    // once sorted reapply filters
    this.applyHistoryFilter();
  }

  // update date range and re get completed checkins
  onDateRangeChange() {
    let newStart = this.range.controls.start.value;
    let newEnd = this.range.controls.end.value;

    if (newStart != null && newEnd != null) {
      this.startTimeStamp = (Date.parse(newStart)/1000);
      this.endTimeStamp = (Date.parse(newEnd)/1000) + 86349;
      this.loadingHistoryOverlayDisplay = 'inherit';
      this.tableHistoryDisplay = 'none';
      this.getCompletedCheckinTableData();
    } else {
      // if range incomplete or empty, revert to no date filter
      this.startTimeStamp = null;
      this.endTimeStamp = null;
      this.getCompletedCheckinTableData();
    }
  }

  //**************************************************************************//
  // FUTURE CODE FOR ALARMS **************************************************//
  // can all be commented out for now                                         //
  //**************************************************************************//

  /* getAlarmsTableData() {
    this.alarmsMasterList = [{status: "Good", value: true},{status: "Warning", value: true},{status: "Bad", value: false}];
    this.alarmsFilteredList = this.alarmsMasterList;
    this.dataSourceAlarms = new MatTableDataSource<any>(this.alarmsFilteredList);
    this.tableAlarmsDisplay = 'inherit';
    this.dataSourceAlarms.paginator = this.paginatorAlarms;
    this.applyAlarmsFilter();
  }

  applyAlarmsFilter() {
    let filterVal = (<HTMLInputElement> document.getElementById("filterAlarmInput")).value;

    // populate the filtered array
    this.dataSourceAlarms.filter = filterVal;
    if (!filterVal) {
      this.alarmsFilteredList = this.alarmsMasterList;
    } else {
        filterVal = filterVal.toLowerCase();
        var newFilteredArr: Array<any> = [];
        this.alarmsMasterList.forEach(entry => {
          try {
            if (entry.status.toLowerCase().includes(filterVal)) {
              newFilteredArr.push(entry);
            }
          } catch (e) {
            //  ignore
            }
          });
        this.alarmsFilteredList = newFilteredArr;
    }


    if (this.dataSourceAlarms.paginator) {
        this.dataSourceAlarms.data = this.alarmsFilteredList;
        if (filterVal != this.lastFilterVal) {
            this.dataSourceAlarms.paginator.firstPage();
        }
        this.dataSourceAlarms.paginator.length = this.alarmsFilteredList.length;
    }
    this.lastFilterVal = filterVal;
    this.sliceFilteredAlarmsArrayForPagination();
  }

  sortAlarmData(sort: Sort) {
    var data = null;
    if (!sort.active || sort.direction === '') {
      data = this.alarmsMasterList;
    } else {
      data = this.alarmsMasterList.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          //case 'name':
            //return compare(a.name, b.name, isAsc);
          case 'status':
            return compare(a.status, b.staus, isAsc);
          case 'onoff':
            return compare(a.value, b.value, isAsc);
          default:
            this.getAlarmsTableData();
            return 0;
        }
      });
    }
    this.applyAlarmsFilter();
  }

  // from the filtered array, populate the subarray with the items on the given page
  sliceFilteredAlarmsArrayForPagination() {
      let startIdx = this.dataSourceAlarms.paginator.pageSize * this.dataSourceAlarms.paginator.pageIndex;
      let endIdx = Math.min((this.dataSourceAlarms.paginator.pageSize * (this.dataSourceAlarms.paginator.pageIndex + 1)),
              this.alarmsFilteredList.length);
      this.alarmsFilteredAndPageSelectedList = this.alarmsFilteredList.slice(startIdx, endIdx);
      this.loadingAlarmsOverlayDisplay = 'none';
  } */
}

function compare(a, b, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}



