import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NocOverviewComponent } from './components/noc-overview/noc-overview.component';
import { UserCheckinComponent } from './components/user-checkin/user-checkin.component';
import {MsalGuard} from "@azure/msal-angular";
import {UserCheckinsComponent} from "./components/user-checkins/user-checkins.component";
import {CheckinDetailsComponent} from "./components/checkin-details/checkin-details.component";
import { NocSiteComponent } from './components/noc-site/noc-site.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NOCGuard } from './services/authentication/noc-guard.guard';
//import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent,
  // },
  {
    path: '',
    component: UserCheckinComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'noc',
    component: NocOverviewComponent,
    canActivate: [
      MsalGuard, NOCGuard
    ]
  },
  {
    path: 'nocSite',
    component: NocSiteComponent,
    canActivate: [
      MsalGuard, NOCGuard
    ]
  },
  {
    path: 'checkin',
    component: UserCheckinComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'checkins',
    component: UserCheckinsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'checkins/:id',
    component: CheckinDetailsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full',
  },
  //{ path: '**', redirectTo: '/login', pathMatch: 'full' }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
