<section class="px-16 md:px-24 py-48 md:py-64 bg-gray-lighter">
  <!-- main container -->
  <div class="custom-container-90">

     <!-- Page title -->
    <h2>Site(s) Currently Checked Into</h2>

     <!-- active/completed toggle menu -->
    <section class="md:px-24 py-0 bg-gray-lighter">
      <div id = "tab-menu"
          class="container-3xl flex mx-auto pt-16 md:pt-24 justify-center border-b border-gray"
          role="tablist"
          aria-orientation="horizontal"
          aria-label="Select an option">

        <!-- Active tab -->
        <button
            [class]="curTab == 'active' ? ACTIVE_TAB_CLASS : INACTIVE_TAB_CLASS"
            id="active-checkins-btn"
            role="tab"
            type="button"
            aria-controls="tab-panel-id-1"
            [attr.aria-selected]="curTab == 'active'"
            [attr.aria-controls]="curTab == 'active'"
            [tabindex]="curTab == 'active' ? 0 : -1"
            (click)="onCheckinTypeSelected('active')">
          <span class="line-clamp-3">
            Active Check-Ins
          </span>
          <span [class]="curTab == 'active' ? ACTIVE_TAB_BORDER_CLASS : INACTIVE_TAB_BORDER_CLASS"></span>
        </button>

        <!-- Completed tab -->
        <button
            [class]="curTab == 'completed' ? ACTIVE_TAB_CLASS : INACTIVE_TAB_CLASS"
            id="completed-checkins-btn"
            role="tab"
            type="button"
            aria-controls="tab-panel-id-1"
            [attr.aria-selected]="curTab == 'completed'"
            [attr.aria-controls]="curTab == 'completed'"
            [tabindex]="curTab == 'completed' ? 0 : -1"
            (click)="onCheckinTypeSelected('completed')">
          <span class="line-clamp-3">
            Past Check-Ins
          </span>
          <span [class]="curTab == 'completed' ? ACTIVE_TAB_BORDER_CLASS : INACTIVE_TAB_BORDER_CLASS"></span>
        </button>
      </div>
    </section>
    <hr class="border-gray"/>

    <!-- loading overlay for tables -->
    <div class="processing-overlay loading-overlay loader-large bg-white" [style.display]="loadingOverlayDisplay">
      <de-loader></de-loader>
    </div>

    <!-- Error message if data fails to load --> 
    <div class="processing-overlay bg-white" [style.display]="tableError">
      <div class="flex justify-center py-32 text-red">
        <img src="assets/icons/status-bad.png" width="20" height="20" alt="bad" class="float-left mx-16 object-contain"> 
        <span *ngIf="curTab == 'active'">Error loading Current Check Ins. Please refresh page or contact support if issue persists.</span>
        <span *ngIf="curTab == 'completed'">Error loading Completed Check Ins. Please refresh page or contact support if issue persists.</span>
      </div>
    </div>

    <!-- table data container -- desktop & mobile -->
    <div id="checkins-table" [style.display]="tableDisplay" class="bg-white">

      <!-- search & sort (mobile only) controls for Active check ins -->
      <div class="table-options-container-active" *ngIf="curTab == 'active'">

        <!-- active search bar -->
        <div class="filter-wrapper">
          <mat-form-field appearance="standard">
            <input matInput id="filterInput" placeholder="Search" (keyup)="applyFilter()">
          </mat-form-field>
        </div>

        <!-- active sort dropdown for mobile views only -->
        <mat-form-field appearance="standard" class="mobile-sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="sortData($event.value)">
            <mat-option *ngFor="let sort of sortFields" [value]="{active: sort.active, direction: sort.direction}">
              {{sort.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- search & sort (mobile only) controls for Completed check ins -->
      <div class="table-options-container-completed" *ngIf="curTab == 'completed'">

        <!-- completed search bar -->
        <div class="filter-wrapper">
          <mat-form-field appearance="standard">
            <input matInput id="filterInput" placeholder="Search" (keyup)="applyFilter()">
          </mat-form-field>
        </div>

        <!-- completed sort dropdown for mobile views only -->
        <mat-form-field appearance="standard" class="mobile-sort">
          <mat-label>Sort By</mat-label>
          <mat-select (selectionChange)="sortData($event.value)">
            <mat-option *ngFor="let sort of sortFields" [value]="{active: sort.active, direction: sort.direction}">
              {{sort.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- completed date range filter control -->
        <mat-form-field appearance="standard" class="date-range">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onDateRangeChange()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>

      <!-- start table layout w/ sorting controls on header row columns-->
      <table matSort (matSortChange)="sortData($event)" class="w-full bg-white border-t border-gray-light">

        <!-- table headers for desktop view only -->
        <thead>
          <tr class="checkin-header hidden sm:table-row border-b border-gray-light font-bold">
            <th mat-sort-header="siteName" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Site Name
            </th>
            <th mat-sort-header="contractor" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Contractor
            </th>
            <th mat-sort-header="purpose" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Purpose
            </th>
            <th mat-sort-header="checkinTime" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Check-In
            </th>
            <th *ngIf="curTab == 'completed'" mat-sort-header="checkoutTime" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Check-Out
            </th>
            <th mat-sort-header="phonenumber" class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Phone#
            </th>
            <th class="p-16 text-blue whitespace-nowrap text-left" scope="col">
              Details
            </th>
          </tr>
        </thead>

        <!-- table rows -->
        <tbody class="block sm:table-row-group" *ngIf="loadingOverlayDisplay == 'none'">
          
          <!-- basic columnar display for tablet/desktop sizes -->
          <tr *ngFor="let checkin of checkinsFilteredAndPageSelectedList" (click)="entryClicked(checkin)"
              class="checkin-row grid sm:table-row grid-rows-auto grid-flow-col gap-y-8 gap-x-24 py-12 sm:py-0 border-b border-gray-light">
            <td class="flex sm:table-cell gap-12 sm:px-16 sm:py-12 align-middle sm:text-left">
              <strong class="flex-shrink-0 w-76">
                {{checkin.SiteID}}
              </strong>
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.LastName}}, {{checkin.FirstName}}
            </td>
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.VisitReason}}
            </td>
            <!-- if date is today, only show time. if date before today but still this year, show month day & time. if not this year, show full date time. -->
            <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <span *ngIf="checkin.CheckInTime.getTime() > yesterday.getTime()">{{checkin.CheckInTime | date: 'h:mm a' }}</span>
              <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() == thisYear">{{checkin.CheckInTime | date: 'MMM dd @ h:mm a' }}</span>
              <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() < thisYear">{{checkin.CheckInTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
            </td>
            <td *ngIf="curTab=='completed'" class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              <span *ngIf="checkin.CheckOutTime.getTime() > yesterday.getTime()">{{checkin.CheckOutTime | date: 'h:mm a' }}</span>
              <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() 
                          && checkin.CheckOutTime.getFullYear() == thisYear">{{checkin.CheckOutTime | date: 'MMM dd @ h:mm a' }}</span>
              <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() 
                          && checkin.CheckOutTime.getFullYear() < thisYear">{{checkin.CheckOutTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
             </td>
             <td class="row-span-3 flex sm:table-cell items-start text-left sm:px-16 py-6 sm:py-12 align-middle sm:text-left">
              {{checkin.PhoneNumber}}
            </td>
            <td class="expand-arrow row-span-3 flex sm:table-cell items-start justify-end sm:pl-32 py-6 sm:py-12 align-middle text-teal-darker">
              <img src= "assets/icons/popout.png" width="20" height="20" alt="open">
            </td>
            <hr class="border-gray mt-16"/>
          </tr>

           <!-- vertical card display for mobile/small tablet sizes -->
          <tr *ngFor="let checkin of checkinsFilteredAndPageSelectedList" (click)="entryClicked(checkin)"
              [class]="curTab == 'active' ? 'checkin-row-card active-card' : 'checkin-row-card completed-card'">
            <div class="float-left w-4/5 pt-8"> 
              <span class="font-bold text-teal-darker pl-8">{{checkin.SiteID}}</span>
            </div>
            <div class="float-right w-1/5 pr-16 pt-16">
              <img class="float-right" src= "assets/icons/popout.png" width="20" height="20" alt="open">
            </div>
            <div class="w-full">
              <span class="pl-16">
                <span class="font-bold">Purpose: </span>{{checkin.VisitReason}}
              </span>
              <br>
              <!-- if date is today, only show time. if date before today but still this year, show month day & time. if not this year, show full date time. -->
              <span class="pl-16">
                <span class="font-bold">Check-In: </span>
                  <span *ngIf="checkin.CheckInTime.getTime() > yesterday.getTime()">{{checkin.CheckInTime | date: 'h:mm a' }}</span>
                  <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() == thisYear">{{checkin.CheckInTime | date: 'MMM dd @ h:mm a' }}</span>
                  <span *ngIf="checkin.CheckInTime.getTime() <= yesterday.getTime() && checkin.CheckInTime.getFullYear() < thisYear">{{checkin.CheckInTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
              </span>
              <br>
              <span *ngIf="curTab=='completed'" class="pl-16">
                <span class="font-bold">Check-Out: </span>
                <span *ngIf="checkin.CheckOutTime.getTime() > yesterday.getTime()">{{checkin.CheckOutTime | date: 'h:mm a' }}</span>
                <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() && checkin.CheckOutTime.getFullYear() == thisYear">{{checkin.CheckOutTime | date: 'MMM dd @ h:mm a' }}</span>
                <span *ngIf="checkin.CheckOutTime.getTime() <= yesterday.getTime() && checkin.CheckOutTime.getFullYear() < thisYear">{{checkin.CheckOutTime | date: 'MMM dd, yyyy @ h:mm a'}}</span>
              </span>
            </div>
            <hr class="border-gray mt-16"/>
          </tr>
        </tbody>
      </table>

      <!-- pagination control -->
      <mat-paginator class="mt-32" #paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        [showFirstLastButtons]="true"
        (page)="sliceFilteredCheckinArrayForPagination()">
      </mat-paginator>
    </div>
  </div>
</section>
