import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject, } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HeaderLink } from "@de-electron/electron-angular-components";
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type MemberOfType = {
  displayName?: string,
  description?: string,
  id?: string
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  memberOfNOC!: MemberOfType;
  mobileMenuOpen: boolean;

  headerLinks: HeaderLink[] = [
    {
      value: "checkin",
      viewValue: "New Check-In",
      icon: "assets/icons/plus.png"
    },
    {
      value: "checkins",
      viewValue: "My Check-Ins",
      icon: "assets/icons/checklist.png"
    }
  ]

  title = 'msal-angular-tutorial';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private authNOC: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.getMemberOfNOC();
      });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() { // Add log out function here
    this.authService.logoutRedirect({
      //postLogoutRedirectUri: 'http://localhost:4200'
      postLogoutRedirectUri: '/'
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    console.log('AppComponent setLoginDisplay() headerLinks: ', this.headerLinks);
    if (!this.loginDisplay) {
      this.headerLinks.push({
        value: "login",
        viewValue: "Sign In",
        icon: "assets/icons/signin.png"
      })
    }
    console.log('AppComponent setLoginDisplay() else headerLinks: ', this.headerLinks);
    let logoutOption = {
      value: "logout",
      viewValue: "Sign Out",
      icon: "assets/icons/signout.png"
    };
    console.log('AppComponent setLoginDisplay() !this.headerLinks.includes(logoutOption): ', !this.headerLinks.includes(logoutOption));
    if (!this.headerLinks.includes(logoutOption) && this.loginDisplay) {
      this.headerLinks.push(logoutOption)
    }


  }

  onNavigate(link: HeaderLink) {

    if (this.mobileMenuOpen) {
      this.toggleMenu();
    }

    if (link.value == 'login') {
      this.login();
      return;
    }
    if (link.value == 'logout') {
      this.logout();
      return;
    }
    // to ensure Tab Title goes back to normal if navigating from NOC Site page
    if (document.title.includes("NOC Site")) {
      document.title = "Telecom Site Access";
    }
    this.router.navigateByUrl('/' + link.value);
  }

  getMemberOfNOC() {
    //this.setLoginDisplay();
    this.authNOC.confirmNOC().subscribe(
      success => {
        console.log('AppComponent getMemberOfNOC() success', success);
        if (success.admin || success.lead) {
          this.headerLinks.push(
            {
              value: "noc",
              viewValue: "NOC",
              icon: "assets/icons/overview.png"
            }
          );
          this.setLoginDisplay();
        } else {
          this.setLoginDisplay();
        }
      },
      (error)=>{
          this.setLoginDisplay();
      }
    )
  }

  toggleMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;

    if (this.mobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
