import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import { Router } from '@angular/router';
import {CheckinEntry} from "../../models/checkin-entry.model";
import { CheckinSite } from '../../models/checkin-site.model';
import {TelaxsCallsService} from "../../services/telaxs-calls.service";
import { ConfirmActionComponent } from '../confirm-action/confirm-action.component';
import { WorkerDetailsComponent } from '../worker-details/worker-details.component';

@Component({
  selector: 'app-checkin-details',
  templateUrl: './checkin-details.component.html',
  styleUrls: ['./checkin-details.component.scss']
})



export class CheckinDetailsComponent implements OnInit {

  //location service unavailable
  locationServiceUnavailable: boolean = false;

  loadingOverlayDisplay = 'inherit';
  //show loader
  showLoader:boolean = true;

  // overlay for successful checkout
  successOverlayDisplay: string = 'none';

  // standard loading overlay
 // loadingOverlayDisplay: string = 'none';

  // whether to display check out button
  enableCheckout: boolean = false;

  // changes mtl display format based on from noc or user
  isNOC: boolean = false;

  // stores site data
  sites: CheckinSite[] = [];
  siteData: CheckinSite = new CheckinSite;

  constructor( @Inject(MAT_DIALOG_DATA) public data: {entry: CheckinEntry, role: string},
               private dialogRef: MatDialogRef<CheckinDetailsComponent>,
               private service: TelaxsCallsService,
               public dialog: MatDialog,
               private router: Router) {
                this.getSiteData(this.data.entry.SiteID);
               }

  ngOnInit(): void {
    console.log('CheckinDetailsComponent ngOnInit() MAT_DIALOG_DATA',this.data.entry, '\nrole: ', this.data.role);
    this.enableCheckout = this.data.role.toLowerCase() == 'user' || this.data.role.toLowerCase() == 'lead';
    this.isNOC = this.data.role.toLowerCase() == 'noc';
    this.getSiteData(this.data.entry.SiteID)
  }

  getSiteData(site: string) {
    this.service.getSitesBySearchTerm(site).subscribe(res =>  {
      console.log(res);

      const json = res.body.results;
      json.forEach((site, index) => {
        let addressParts = site.physical_address.split(' | ');
        let streetAddress:String = addressParts[0];
        let city:String = addressParts[1];
        let state:String = addressParts[2];
        let zip:String = addressParts[3];
        let temp = new CheckinSite()
        temp.city = city;
        temp.state = state;
        temp.streetAddress = streetAddress;
        temp.siteAbbreviation = site.slug;
        temp.siteName = site.display;
        temp.regionName = site.region.display;
        this.sites.push(temp);
      })


      this.locationServiceUnavailable= this.locationServiceUnavailable? true:false;
      this.showLoader = false;
      if(this.sites.length > 0 && this.sites != null){
        // return top 1 exact match from sites
        this.siteData = this.sites.filter(s => {
          return s.siteAbbreviation === site;
        })[0];
      }
    }, (error)=>{
      this.locationServiceUnavailable = true;
      this.showLoader = false;
      let erroredSiteData: CheckinSite = new CheckinSite;
      erroredSiteData.siteAbbreviation = this.data.entry.SiteID;
      this.siteData = erroredSiteData;
    });
  }

  // for noc view only -- open contractor details
  openWorker(entry: CheckinEntry) {

    this.dialog.open(WorkerDetailsComponent, {
      data: {entry: entry, parent: "noc"}
    })
  }

  // for noc view only -- open site details page in new tab
  openSite(site) {

    // open selected site in new tab with site as window name
    var baseUrl = window.location.href.replace(this.router.url, '');
    window.open(baseUrl + "/nocSite?abrv=" + site, site);
  }

  close() {
    this.dialogRef.close('CANCEL');
  }

  // open confirm action dialog
  confirmCheckOut (entry: CheckinEntry) {
    this.dialog.open(ConfirmActionComponent, {
      data: {entry: entry, confirmationType: "checkout"}
    }).afterClosed().subscribe(res => {
      // if user confirmed, then checkout
      if (res == 'CONFIRM'){
        this.checkOut()
      }
    });
  }

  // check user out
  checkOut() {
    this.loadingOverlayDisplay = 'inherit'
    this.service.checkout(this.data.entry.ID).subscribe(res =>{
        let ref = this.dialogRef;
        this.loadingOverlayDisplay = 'none'
        this.successOverlayDisplay = 'inherit';
        setTimeout(function() {
          this.successOverlayDisplay = 'none';
          ref.close("CHECKED OUT");
        }, 1000);
      },
      () => {},
      () => {})
  }
}
